import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import { toast } from 'react-toastify';
import { updateAccount } from '../../reducers/userReducer';

const MyAccountSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const MyAccountContentDiv = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
`;

const MyAccountContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 60px;
  width: 50%;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const MyAccountContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
`;

const MyAccountContentFormLabel = styled.label`
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const MyAccountContentFormInput = styled.input`
  height: 58px;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const HRLine = styled.hr`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const UpdateButton = styled.button`
  width: 100%;
  height: 58px;
  margin-top: 15px;
  font-size: 22px;
  cursor: pointer;
  background: transparent
    linear-gradient(284deg, #966118 0%, #e2ba76 49%, #966118 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
`;

export default function MyAccountComponent() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);

  const updateUser = async () => {
    let data = {
      email: email.trim(),
      phone: phone.trim(),
      address: user.address.trim(),
    };
    try {
      const res = await dispatch(
        updateAccount({
          data,
        })
      );

      if (updateAccount.fulfilled.match(res)) {
        dispatch({ type: 'UPDATE_SUCCESS', payload: res.payload });
        toast('Account has been updated successfully!');
      } else {
        if (res.payload.statusCode === 401) {
          dispatch({ type: 'LOG_OUT' });
        }
        toast(res.payload.message);
      }
    } catch (error) {
      toast(error?.message);
    }
  };

  return (
    <MyAccountSection>
      <CommonContainer>
        <MyAccountContentDiv>
          <MyAccountContentFormDiv>
            <MyAccountContentFormTitle>My Account</MyAccountContentFormTitle>
            <MyAccountContentFormLabel>Username</MyAccountContentFormLabel>
            <MyAccountContentFormInput defaultValue={user.user_name} disabled />
            <MyAccountContentFormLabel>Email</MyAccountContentFormLabel>
            <MyAccountContentFormInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <MyAccountContentFormLabel>Phone</MyAccountContentFormLabel>
            <MyAccountContentFormInput
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <HRLine />
            <UpdateButton onClick={() => updateUser()}>Update</UpdateButton>
          </MyAccountContentFormDiv>
        </MyAccountContentDiv>
      </CommonContainer>
    </MyAccountSection>
  );
}
