import React from 'react';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import BannerImage from '../../assets/images/planets.png';

const BannerSection = styled.section`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  &:after {
    content: '';
    position: absolute;
    background: #0000009e;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
    top: -4px;
  }
  @media (max-width: 1600px) {
    min-height: 91vh;
  }
  @media only screen and (max-width: 991px) {
    padding: 70px 0 20px 0;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const BannerContent = styled.div`
  position: relative;
  z-index: 1;
`;

const BannerContentDiv = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
  align-items: center;
  justify-content: space-evenly;
`;

const BannerContentItemDiv = styled.div`
  @media (max-width: 767px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.width / 12) * 100}%;
    flex: 0 0 ${(props) => (props.width / 12) * 100}%;
    max-width: ${(props) => (props.width / 12) * 100}%;
  }
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
`;

const CommonH1 = styled.h1`
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 15px;
  @media only screen and (max-width: 991px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const CommonP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bolder;
`;

const CommonButtons = styled.button`
  display: inline-block;
  background: rgb(255 255 255 / 12%);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  padding: 9px 16px;
  font-weight: 200;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  margin-left: 0.5rem;
  &:hover {
    box-shadow: none;
    background-color: rgb(183 145 85);
  }
`;

const BannerImg = styled.img`
  width: 700px;
  @media screen and (max-width: 1921px) and (min-width: 1800px) {
    width: 1000px;
  }
  @media (max-width: 1600px) {
    width: 800px;
  }
  @media (max-width: 1366px) {
    width: 100%;
  }
`;

export default function Banner() {
  return (
    <BannerSection>
      <CommonContainer>
        <BannerContent>
          <BannerContentDiv>
            <BannerContentItemDiv width={12} smwidth={5}>
              <CommonH1>Eloncoin</CommonH1>
              <CommonP>"Mars, here we come!!"</CommonP>
              <CommonP>
                "I could either watch it happen or be a part of it"
              </CommonP>
              <CommonButtons>Join ElonCoin</CommonButtons>
            </BannerContentItemDiv>
            <BannerContentItemDiv width={12} smwidth={7}>
              <BannerImg src={BannerImage} />
            </BannerContentItemDiv>
          </BannerContentDiv>
        </BannerContent>
      </CommonContainer>
    </BannerSection>
  );
}
