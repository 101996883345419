import QrCode from 'qrcode.react';
import LogoImage from '../../assets/images/logo_3.0@2x.png';

function QrCard({ qrRef, url, bgColor, qrColor, customImg, noImg }) {
  let imgCustom = undefined;

  noImg
    ? (imgCustom = null)
    : customImg
      ? (imgCustom = customImg)
      : (imgCustom = LogoImage);

  return (
    <article className="card">
      <div className="qr-box" ref={qrRef} style={{ backgroundColor: bgColor }}>
        <QrCode
          size={250}
          value={url}
          bgColor={bgColor}
          fgColor={qrColor}
          level="H"
          includeMargin
          imageSettings={{
            src: imgCustom,
            height: 45,
            width: 45,
            excavate: true,
          }}
        />
      </div>
    </article>
  );
}

export default QrCard;
