import React from 'react';
import styled from 'styled-components';
import HeaderComponent from '../../components/HeaderComponent';
import Faq from '../../components/Faq';
import Contactus from '../../components/Contactus';

import UpArrowImage from '../../assets/images/uparrow.png';
import { AdminAuthenticatedComponent } from '../../components/base/admin_authenticated_component';
import { useParams } from 'react-router-dom';
import HistoryComponent from '../../components/HistoryComponent';

const PageLayout = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  float: left;
`;

const ScrollToTop = styled.button`
  opacity: 1;
  visibility: visible;
  display: inline-block;
  background-color: #b79155;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition:
    background-color 0.3s,
    opacity 0.5s,
    visibility 0.5s;
  box-shadow: none;
  border: none;
  z-index: 1000;
  text-decoration: none;
  cursor: pointer;
`;

const UpArrowImg = styled.img`
  height: 60%;
`;

export default function History() {
  const { username } = useParams();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AdminAuthenticatedComponent username={username}>
      <PageLayout>
        <HeaderComponent />
        <HistoryComponent username={username} />
        <Faq />
        <Contactus />
        <ScrollToTop onClick={() => scrollToTop()}>
          <UpArrowImg src={UpArrowImage} />
        </ScrollToTop>
      </PageLayout>
    </AdminAuthenticatedComponent>
  );
}
