import axiosInstance from '../utils/axios';

const getNewBalance = async (address) => {
  const res = await axiosInstance.get(`/rpc/new-balance/${address}`);
  return Promise.resolve(res.data);
};

const getOldBalance = async (address) => {
  const res = await axiosInstance.get(`/rpc/old-balance/${address}`);
  return Promise.resolve(res.data);
};

const getAddressInfo = async (address) => {
  const res = await axiosInstance.get(`/rpc/address-info/${address}`);
  return Promise.resolve(res.data);
};

const swap = async (username) => {
  const res = await axiosInstance.post(`/rpc/swap/${username}`);
  return Promise.resolve(res.data);
};

const RPCService = {
  getAddressInfo,
  getNewBalance,
  getOldBalance,
  swap,
};

export default RPCService;
