import React from 'react';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/starbg.png';
import WindowsImage from '../../assets/images/windows.png';
import LinuxImage from '../../assets/images/linux.png';
import MacOSImage from '../../assets/images/apple.png';
import SourceImage from '../../assets/images/command-window.png';
import NotesImage from '../../assets/images/notes.png';
import CodingImage from '../../assets/images/coding.png';
import WalletPassImage from '../../assets/images/wallet-passes-app.png';
import AndroidImage from '../../assets/images/android.png';

const DownloadSection = styled.section`
  background-image: url(${StarBackgroundImage});
  padding-bottom: 0;
  padding: 100px 0 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: -150px;
  @media (max-width: 992px) {
    padding-bottom: 95px;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 40px;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const DownloadContentDiv = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;
`;

const DownloadContentItemDiv = styled.div`
  text-align: center;
  padding: 20px;
  @media (max-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
  @media (min-width: 768px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    max-width: ${(props) => (props.mdwidth / 12) * 100}%;
  }
  @media (min-width: 992px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    max-width: ${(props) => (props.lgwidth / 12) * 100}%;
  }
`;

const FeatureBoxDiv = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 4px;
  margin-top: 30px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  height: 380px;
`;

const FeatureTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const FeatureImg = styled.img`
  margin-right: 10px;
`;

const FeatureH5 = styled.h5`
  font-size: 28px;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
`;

const FeatureP = styled.p`
  color: rgb(255 255 255 / 50%);
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
`;

const DownloadP = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 400 !important;
  margin-top: 20px;
`;

const DownloadLink = styled.span`
  color: #c59d5f;
  font-size: 20px;
  font-weight: 300;
  text-transform: none;
  margin: 0 10px;
  cursor: pointer;
  &:hover {
    text-decoration-line: underline;
  }
`;

const FeatureItalic = styled.p`
  font-style: italic;
  padding: 20px 0 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
`;

const FeatureLinuxDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadLinuxP = styled.p`
  color: #fff;
  font-size: 28px;
  font-weight: 400 !important;
  margin-left: 15px;
  margin-right: 15px;
`;

const DownloadButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DownloadButton = styled.button`
  background: rgb(255 255 255 / 12%);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  padding: 9px 16px;
  font-weight: 200;
  min-width: 200px;
  margin: 0 10px;
  min-height: 50px;
  cursor: pointer;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: none;
    background-color: rgb(183 145 85);
  }
`;

const DownloadButtonIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export default function Faq() {
  const download = (link) => {
    window.open(link);
  };

  return (
    <DownloadSection>
      <CommonContainer>
        <DownloadContentDiv>
          <DownloadContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureTitleDiv>
                <FeatureImg src={AndroidImage} />
                <FeatureH5>Android</FeatureH5>
              </FeatureTitleDiv>
              <FeatureP>STABLE RELEASE</FeatureP>
              <DownloadP>
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/Eloncoin/releases/download/v1.0.2/ElonMask-Android_v1.0.0.apk'
                    )
                  }
                >
                  APK
                </DownloadLink>
                |<DownloadLink title={'Coming Soon'}>PlayStore</DownloadLink>
              </DownloadP>
            </FeatureBoxDiv>
          </DownloadContentItemDiv>
          <DownloadContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureTitleDiv>
                <FeatureImg src={WindowsImage} />
                <FeatureH5>Windows</FeatureH5>
              </FeatureTitleDiv>
              <FeatureP>STABLE RELEASE</FeatureP>
              <DownloadP>
                64bit:
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/releases/download/0.1.0/Emc-Frame.Setup.0.1.0.exe'
                    )
                  }
                >
                  Installer
                </DownloadLink>
                |
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/releases/download/0.1.0/Emc-Frame-win-unpacked-0.1.0.zip'
                    )
                  }
                >
                  ZIP
                </DownloadLink>
              </DownloadP>
              <FeatureItalic>
                Supported Windows versions are 7 and higher.
              </FeatureItalic>
            </FeatureBoxDiv>
          </DownloadContentItemDiv>
          <DownloadContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureTitleDiv>
                <FeatureImg src={LinuxImage} />
                <FeatureH5>Linux</FeatureH5>
              </FeatureTitleDiv>
              <FeatureP>STABLE RELEASE</FeatureP>
              <FeatureLinuxDiv>
                <DownloadLinuxP>arm</DownloadLinuxP>
                <DownloadLinuxP>amd</DownloadLinuxP>
              </FeatureLinuxDiv>
              <DownloadP>
                64bit:
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/releases/download/0.1.0/emc-frame_0.1.0_amd64.deb'
                    )
                  }
                >
                  Installer
                </DownloadLink>
                |
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/releases/download/0.1.0/Emc-Frame-linux-arm64-unpacked-0.1.0.zip'
                    )
                  }
                >
                  ZIP
                </DownloadLink>
                64bit:
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/releases/download/0.1.0/emc-frame_0.1.0_arm64.deb'
                    )
                  }
                >
                  Installer
                </DownloadLink>
                |
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/releases/download/0.1.0/Emc-Frame-linux-unpacked-0.1.0.zip'
                    )
                  }
                >
                  Zip
                </DownloadLink>
              </DownloadP>
            </FeatureBoxDiv>
          </DownloadContentItemDiv>
          <DownloadContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureTitleDiv>
                <FeatureImg src={MacOSImage} />
                <FeatureH5>macOS</FeatureH5>
              </FeatureTitleDiv>
              <FeatureP>STABLE RELEASE</FeatureP>
              <DownloadP>
                64bit:
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/Eloncoin/releases/download/v1.0.2/ElonCoin-1.0.2-osx.dmg'
                    )
                  }
                >
                  Installer
                </DownloadLink>
                |
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/Eloncoin/releases/download/v1.0.2/ElonCoin-1.0.2-osx64.tar.gz'
                    )
                  }
                >
                  Tarball
                </DownloadLink>
              </DownloadP>
              <FeatureItalic>
                Supported Windows versions are 7 and higher.
              </FeatureItalic>
            </FeatureBoxDiv>
          </DownloadContentItemDiv>
          <DownloadContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureTitleDiv>
                <FeatureImg src={SourceImage} />
                <FeatureH5>Sources</FeatureH5>
              </FeatureTitleDiv>
              <FeatureP>STABLE RELEASE</FeatureP>
              <DownloadP>
                <DownloadLink
                  onClick={() =>
                    download(
                      'https://github.com/EMCoin/emc-frame/archive/refs/tags/0.1.0.tar.gz'
                    )
                  }
                >
                  Tarball
                </DownloadLink>
                |
                <DownloadLink
                  onClick={() => download('https://github.com/EMCoin/emc-frame')}
                >
                  Github
                </DownloadLink>
              </DownloadP>
            </FeatureBoxDiv>
          </DownloadContentItemDiv>
        </DownloadContentDiv>
        <DownloadButtonDiv>
          <DownloadButton>
            <DownloadButtonIcon src={NotesImage} />
            Release Notes
          </DownloadButton>
          <DownloadButton>
            <DownloadButtonIcon src={CodingImage} />
            Source Code
          </DownloadButton>
          <DownloadButton>
            <DownloadButtonIcon src={WalletPassImage} />
            Wallet Guides
          </DownloadButton>
        </DownloadButtonDiv>
      </CommonContainer>
    </DownloadSection>
  );
}
