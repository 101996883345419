import React from 'react';
import styled from 'styled-components';
import { formatAddress } from '../../utils/utils';
import { ethers } from 'ethers';

const HistoryTr = styled.tr``;

const HistoryTd = styled.td`
  padding: 8px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: ${(props) =>
    props.cellalign !== null ? props.cellalign : 'left'};
  max-width: ${(props) => (props.maxwidth !== null ? props.maxwidth : 'unset')};
`;

const TransactionLink = styled.a`
  color: white;
`;

export default function HistoryItemComponent({
  history,
  username,
  oldAddress,
}) {
  return (
    <HistoryTr>
      <HistoryTd>
        <TransactionLink
          href={`${process.env.REACT_APP_EXPLORER_URL}/tx/${history.transactionHash}`}
          target="_blank"
        >
          {username}
        </TransactionLink>
      </HistoryTd>
      <HistoryTd>
        <TransactionLink
          href={`${process.env.REACT_APP_OLD_EXPLORER_URL}/address/${oldAddress}`}
          target="_blank"
        >
          {formatAddress(oldAddress, 4, 30)}
        </TransactionLink>
      </HistoryTd>
      <HistoryTd>
        <TransactionLink
          href={`${process.env.REACT_APP_EXPLORER_URL}/address/${history.receiver}`}
          target="_blank"
        >
          {formatAddress(history.receiver, 4, 40)}
        </TransactionLink>
      </HistoryTd>
      <HistoryTd>{ethers.utils.formatEther(history.amount)}</HistoryTd>
      <HistoryTd maxwidth={'150px'}>
        {new Date(history.created_at).toDateString()}
      </HistoryTd>
    </HistoryTr>
  );
}
