import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { formatAddress, formatBigNumber } from '../../utils/utils';
import { toast } from 'react-toastify';
import {
  getAddressInfo,
  getNewCoinBalance,
  getOldCoinBalance,
  swapOldCoin,
} from '../../reducers/rpcReducer';
import { ethers } from 'ethers';
import { Icon } from '@iconify/react';

const SwapTr = styled.tr``;

const SwapTd = styled.td`
  padding: 8px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: ${(props) =>
    props.cellalign !== null ? props.cellalign : 'left'};
`;

const SwapTableButton = styled.button`
  cursor: pointer;
  background: ${(props) =>
    props.disabled
      ? `grey`
      : `transparent
  linear-gradient(284deg, #966118 0%, #e2ba76 49%, #966118 100%) 0% 0%
  no-repeat padding-box`};
  color: #fff;
  font-size: 12px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const IconContainer = styled.span`
  cursor: pointer;
  margin: 2px;
`;

const HistoryLink = styled.a`
  color: white;
`;

export default function SwapComponent({ swap, adminSwapperContract }) {
  const dispatch = useDispatch();
  const [newBalance, setNewBalance] = useState(null);
  const [oldBalance, setOldBalance] = useState(null);
  const [copied, setCopied] = useState(false);
  const [newCopied, setNewCopied] = useState(false);

  const [swapped, setSwapped] = useState(0);
  const [scriptPubKey, setScriptPubkey] = useState(null);
  const [swappedLoading, setSwappedtLoading] = useState(true);
  const [oldBalanceLoading, setOldBalanceLoading] = useState(true);
  const [newBalanceLoading, setNewBalanceLoading] = useState(true);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        toast('Old Eloncoin address has been copied!');
      })
      .catch((err) => {
        toast(err.message);
      });
  };

  const copyNewToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setNewCopied(true);
        setTimeout(() => {
          setNewCopied(false);
        }, 2000);
        toast('New Elonchain address has been copied!');
      })
      .catch((err) => {
        toast(err.message);
      });
  };

  const getSwapped = useCallback(async () => {
    setSwappedtLoading(true);
    adminSwapperContract
      .swappedAmountByReceiverPubKey(scriptPubKey)
      .then((res) => {
        setSwapped(ethers.utils.formatUnits(res, 8));
      })
      .catch((err) => {
        console.log(err?.message);
      })
      .finally(() => {
        setSwappedtLoading(false);
      });
  }, [adminSwapperContract, scriptPubKey]);

  useEffect(() => {
    if ((adminSwapperContract, scriptPubKey)) {
      getSwapped();
    }
  }, [adminSwapperContract, scriptPubKey, getSwapped]);

  const getNewBalance = useCallback(
    async (address) => {
      setNewBalanceLoading(true);
      try {
        const res = await dispatch(
          getNewCoinBalance({
            address,
          })
        );

        if (getNewCoinBalance.fulfilled.match(res)) {
          setNewBalance(res.payload);
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          setNewBalance(0);
        }
      } catch (error) {
        setNewBalance(0);
      }
      setNewBalanceLoading(false);
    },
    [dispatch]
  );

  const getOldBalance = useCallback(
    async (address) => {
      setOldBalanceLoading(true);
      try {
        const res = await dispatch(
          getOldCoinBalance({
            address,
          })
        );

        if (getOldCoinBalance.fulfilled.match(res)) {
          setOldBalance(res.payload);
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          setOldBalance(0);
        }
      } catch (error) {
        setOldBalance(0);
      }
      setOldBalanceLoading(false);
    },
    [dispatch]
  );

  const getAddressInfomation = useCallback(
    async (address) => {
      try {
        const res = await dispatch(
          getAddressInfo({
            address,
          })
        );

        if (getAddressInfo.fulfilled.match(res)) {
          const oldPubKey = res.payload?.info?.scriptPubKey ?? null;
          setScriptPubkey(ethers.utils.hexZeroPad(`0x${oldPubKey}`, 32));
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          setScriptPubkey(null);
        }
      } catch (error) {
        console.log(error?.message);
        setScriptPubkey(null);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (swap.address) {
      getNewBalance(swap.address);
    } else {
      setNewBalance(0);
    }
  }, [swap.address, getNewBalance]);

  useEffect(() => {
    if (swap.oldAddress) {
      getOldBalance(swap.oldAddress);
      getAddressInfomation(swap.oldAddress);
    } else {
      setOldBalance(0);
    }
  }, [swap.oldAddress, getOldBalance, getAddressInfomation]);

  const swapCoin = async () => {
    setSwappedtLoading(true);
    try {
      const res = await dispatch(
        swapOldCoin({
          username: swap.user_name,
        })
      );

      if (swapOldCoin.fulfilled.match(res)) {
        toast('You have swapped successfully!');
        getNewBalance(swap.address);
        getSwapped();
      } else {
        if (res.payload.statusCode === 401) {
          dispatch({ type: 'LOG_OUT' });
        }
        setSwappedtLoading(false);
        toast(res.payload.message);
      }
    } catch (error) {
      toast(error?.message);
      setSwappedtLoading(false);
    }
  };

  return (
    <SwapTr>
      <SwapTd>
        <HistoryLink href={`/history/${swap.user_name}`}>
          {swap.user_name}
        </HistoryLink>
      </SwapTd>
      <SwapTd>
        {formatAddress(swap.oldAddress, 4, 30)}
        <br />
        {oldBalanceLoading || swappedLoading
          ? 'Loading...'
          : `${formatBigNumber(oldBalance - swapped)} EMC`}
        <IconContainer
          title="Copy"
          onClick={() => {
            copyToClipboard(swap.oldAddress);
          }}
        >
          {copied ? (
            <Icon icon="material-symbols:check" />
          ) : (
            <Icon icon="material-symbols:content-copy" />
          )}
        </IconContainer>
      </SwapTd>
      <SwapTd>
        {formatAddress(swap.address, 4, 40)}
        <br />
        {newBalanceLoading
          ? 'Loading...'
          : `${formatBigNumber(newBalance)} EMC`}
        <IconContainer
          title="Copy"
          onClick={() => {
            copyNewToClipboard(swap.address);
          }}
        >
          {newCopied ? (
            <Icon icon="material-symbols:check" />
          ) : (
            <Icon icon="material-symbols:content-copy" />
          )}
        </IconContainer>
      </SwapTd>
      <SwapTd cellalign="center">
        <SwapTableButton
          disabled={
            oldBalanceLoading ||
            swappedLoading ||
            newBalanceLoading ||
            !(oldBalance - swapped) ||
            oldBalance - swapped < 1
          }
          onClick={() => {
            swapCoin();
          }}
        >
          Swap
        </SwapTableButton>
      </SwapTd>
    </SwapTr>
  );
}
