import React, { useState } from 'react';
import styled from 'styled-components';

const AccordionDiv = styled.div`
  margin-bottom: 20px;
`;

const AccordionTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff80;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 1rem;
`;

const AccordionTitle = styled.div`
  user-select: none;
`;

const AccordionContentDiv = styled.div`
  background: #ffffff;
  color: #000;
  padding: 1rem;
  text-align: left;
  display: ${(props) => (props.active === 1 ? 'block' : 'none')};
  user-select: none;
`;

const Accordion = ({ title, content }) => {
  const [active, setactive] = useState(0);

  return (
    <AccordionDiv>
      <AccordionTitleDiv onClick={() => setactive(active === 0 ? 1 : 0)}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionTitle>{active ? '-' : '+'}</AccordionTitle>
      </AccordionTitleDiv>
      <AccordionContentDiv active={active}>{content}</AccordionContentDiv>
    </AccordionDiv>
  );
};

export default Accordion;
