import React from 'react';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/starbg.png';
import LogoImage from '../../assets/images/logo_3.0@2x.png';
import TwitterImage from '../../assets/images/Twitter.png';
import DiscordImage from '../../assets/images/Discord.png';

const ContactusSection = styled.section`
  background-image: url(${StarBackgroundImage});
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const ContactusTitleDiv = styled.div`
  text-align: center;
`;

const ContactusSpan = styled.span`
  display: inline-block;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
  user-select: none;
`;

const ContactusProfileDiv = styled.div`
  text-align: center;
`;

const ContactusProfileTitle = styled.p`
  max-width: 300px;
  margin: 0 auto;
`;

const ProfileImageDiv = styled.div`
  width: 200px;
  margin: 20px auto 20px auto;
  @media (max-width: 767px) {
    width: 150px;
  }
`;

const ProfileImg = styled.img`
  user-select: none;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const SubscribeInputDiv = styled.div`
  display: flex;
  max-width: 400px;
  margin: 15px auto;
  padding: 4px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #fff;
  background: #fff;
  @media (max-width: 767px) {
    padding: 6px;
  }
`;

const SubscribeInput = styled.input`
  background: transparent;
  border: 0;
  outline: none;
  color: #000;
  box-shadow: none;
  font-size: 14px;
  height: 36px;
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  &:focus {
    background: transparent;
    border: 0;
    outline: none;
    color: #000;
    box-shadow: none;
  }
  &:placeholder {
    color: #000;
    font-weight: normal;
  }
  @media (max-width: 767px) {
    padding: 6px;
  }
`;

const SubscribeButton = styled.button`
  cursor: pointer;
  background: transparent
    linear-gradient(284deg, #966118 0%, #e2ba76 49%, #966118 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const SocialIconsDiv = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 80px;
`;

const SocialIcons = styled.img`
  height: 30px;
  margin: 10px;
  cursor: pointer;
`;

const CopyRightDiv = styled.div`
  text-align: center;
  font-size: 12px;
  border-top: 1px solid rgb(255 255 255 / 30%);
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.3);
`;

const CommonP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bolder;
`;

export default function Contactus() {
  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <ContactusSection id="contactus">
      <CommonContainer>
        <ContactusTitleDiv>
          <ContactusSpan>Got Questions? Contact Us</ContactusSpan>
        </ContactusTitleDiv>
        <ContactusProfileDiv>
          <ProfileImageDiv>
            <ProfileImg src={LogoImage} />
          </ProfileImageDiv>
          <ContactusProfileTitle>
            Subscribe to be informed about ElonCoin
          </ContactusProfileTitle>
        </ContactusProfileDiv>
        <SubscribeInputDiv>
          <SubscribeInput type="email" placeholder="Email address" />
          <SubscribeButton>Subscribe</SubscribeButton>
        </SubscribeInputDiv>
        <SocialIconsDiv>
          <SocialIcons
            src={TwitterImage}
            onClick={() => openNewTab('https://twitter.com/GetEloncoin')}
          />
          <SocialIcons
            src={DiscordImage}
            onClick={() => openNewTab('http://discord.gg/CX3rSyU29t')}
          />
        </SocialIconsDiv>
        <CopyRightDiv>
          <CommonP>ElonCoin © 2021 All rights reserved</CommonP>
        </CopyRightDiv>
      </CommonContainer>
    </ContactusSection>
  );
}
