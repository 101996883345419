import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import SilverBackgroundImage from '../../assets/images/silverBg.png';
import PCImage from '../../assets/images/pc.png';

const AdvancedSection = styled.section`
  background-image: url(${SilverBackgroundImage});
  color: #000;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const AdvancedContent = styled.div`
  position: relative;
  z-index: 1;
`;

const AdvancedTextDiv = styled.div`
  position: relative;
  padding: 100px 0;
  margin-top: 270px;
  @media (max-width: 767px) {
    position: relative;
    padding: 40px 0 0 0;
  }
`;

const AdvancedContentDiv = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const AdvancedContentItemDiv = styled.div`
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
  @media (min-width: 768px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    max-width: ${(props) => (props.mdwidth / 12) * 100}%;
  }
  @media (min-width: 992px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    max-width: ${(props) => (props.lgwidth / 12) * 100}%;
  }
`;

const CommonH1Black = styled.h1`
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 15px;
  color: #000;
  @media only screen and (max-width: 991px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const CommonPBlack = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bolder;
  color: #000;
`;

const CommonButtonsBlack = styled.button`
  background: #000;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  padding: 9px 16px;
  font-weight: 200;
  @media only screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const AdvancedImgDiv = styled.div`
  @media (min-width: 767px) {
    position: relative;
    right: 0;
    top: 28px;
    max-width: 100%;
    min-width: auto;
    z-index: 9;
    transform: none;
  }
  @media (min-width: 1024px) {
    top: 0px;
    max-width: 716px;
    min-width: 400px;
    z-index: 9;
  }
`;

const AdvancedImg = styled.img`
  width: 100%;
`;

export default function Advanced() {
  const handleDownloadClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AdvancedSection id="wallet">
      <CommonContainer>
        <AdvancedContent>
          <AdvancedTextDiv>
            <AdvancedContentDiv>
              <AdvancedContentItemDiv smwidth={12} mdwidth={5} lgwidth={6}>
                <CommonH1Black>Advanced Wallet</CommonH1Black>
                <CommonPBlack>
                  It's the fastest and most scalable digital asset, enabling
                  real-time global payments anywhere in the world.
                </CommonPBlack>
                <Link to="/download">
                  <CommonButtonsBlack onClick={handleDownloadClick}>
                    Download wallet
                  </CommonButtonsBlack>
                </Link>
              </AdvancedContentItemDiv>
              <AdvancedContentItemDiv smwidth={12} mdwidth={7} lgwidth={6}>
                <AdvancedImgDiv>
                  <AdvancedImg src={PCImage} />
                </AdvancedImgDiv>
              </AdvancedContentItemDiv>
            </AdvancedContentDiv>
          </AdvancedTextDiv>
        </AdvancedContent>
      </CommonContainer>
    </AdvancedSection>
  );
}
