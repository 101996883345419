import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import { AuthComponent } from '../base/auth_component';
import { login } from '../../reducers/userReducer';
import { toast } from 'react-toastify';

const LoginSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
  @media (max-width: 1600px) {
    min-height: 91vh;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const LoginContentDiv = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
`;

const LoginContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 60px;
  width: 50%;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const LoginContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
`;

const LoginContentFormLabel = styled.label`
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const LoginContentFormInput = styled.input`
  height: 58px;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const HRLine = styled.hr`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const LoginButton = styled.button`
  width: 100%;
  height: 58px;
  margin-top: 15px;
  font-size: 22px;
  cursor: pointer;
  background: transparent
    linear-gradient(284deg, #966118 0%, #e2ba76 49%, #966118 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  font-family: Poppins, sans-serif;
  color: #ff0000;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
`;

const Loader = styled.div`
  margin-left: 10px;
  border-width: 6px;
  border-style: solid;
  border-color: #fff;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 2s infinite;
  animation: spin 2s infinite;

  &:before,
  &:after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: purple;
    position: absolute;
    left: 0.125rem;
  }

  &:before {
    top: 0.063rem;
  }

  &:after {
    bottom: 0.063rem;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function LoginComponent() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const loginUser = async () => {
    let data = {
      user_name: username.trim(),
      password: password.trim(),
    };
    try {
      const res = await dispatch(
        login({
          data,
        })
      );
      if (login.fulfilled.match(res)) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.payload });
        toast('You have logged in successfully!');
      } else {
        toast(res.payload.message);
      }
    } catch (error) {
      toast(error?.message);
    }
  };

  return (
    <AuthComponent>
      <LoginSection>
        <CommonContainer>
          <LoginContentDiv>
            <LoginContentFormDiv>
              <LoginContentFormTitle>Login</LoginContentFormTitle>
              <LoginContentFormLabel>Username</LoginContentFormLabel>
              <LoginContentFormInput
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <LoginContentFormLabel>Password</LoginContentFormLabel>
              <LoginContentFormInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <HRLine />
              <LoginButton onClick={() => loginUser()}>
                Login
                {loading && <Loader />}
              </LoginButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </LoginContentFormDiv>
          </LoginContentDiv>
        </CommonContainer>
      </LoginSection>
    </AuthComponent>
  );
}
