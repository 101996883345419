import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import { changePassword } from '../../reducers/userReducer';
import { toast } from 'react-toastify';

const SettingsSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
  @media (max-width: 1600px) {
    min-height: 91vh;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const SettingsContentDiv = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
`;

const SettingsContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 60px;
  width: 50%;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const SettingsContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
`;

const SettingsContentFormLabel = styled.label`
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SettingsContentFormInput = styled.input`
  height: 58px;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const HRLine = styled.hr`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const UpdateButton = styled.button`
  width: 100%;
  height: 58px;
  margin-top: 15px;
  font-size: 22px;
  cursor: pointer;
  background: transparent
    linear-gradient(284deg, #966118 0%, #e2ba76 49%, #966118 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
`;

const ErrorMessage = styled.p`
  font-family: Poppins, sans-serif;
  color: #ff0000;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
`;

export default function SettingsComponent() {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.user);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const updatePassword = async () => {
    if (newPassword === confirmPassword) {
      let data = {
        oldPassword: oldPassword.trim(),
        newPassword: newPassword.trim(),
      };
      try {
        const res = await dispatch(
          changePassword({
            data,
          })
        );

        if (changePassword.fulfilled.match(res)) {
          dispatch({ type: 'UPDATE_SUCCESS', payload: res.payload });
          toast('Password has been updated successfully!');
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          toast(res.payload.message);
        }
      } catch (error) {
        toast(error?.message);
      }
    } else {
      toast('Passwords do NOT match!');
    }
  };

  return (
    <SettingsSection>
      <CommonContainer>
        <SettingsContentDiv>
          <SettingsContentFormDiv>
            <SettingsContentFormTitle>Change Password</SettingsContentFormTitle>
            <SettingsContentFormLabel>
              Current Password
            </SettingsContentFormLabel>
            <SettingsContentFormInput
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              type="password"
            />
            <SettingsContentFormLabel>New Password</SettingsContentFormLabel>
            <SettingsContentFormInput
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
            />
            <SettingsContentFormLabel>
              Confirm Password
            </SettingsContentFormLabel>
            <SettingsContentFormInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
            />
            <HRLine />
            <UpdateButton onClick={() => updatePassword()}>Change</UpdateButton>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </SettingsContentFormDiv>
        </SettingsContentDiv>
      </CommonContainer>
    </SettingsSection>
  );
}
