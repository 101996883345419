import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/starbg.png';

const UserGuideSection = styled.section`
  background-image: url(${StarBackgroundImage});
  padding-bottom: 0;
  padding: 100px 0 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: -150px;
  @media (max-width: 992px) {
    padding-bottom: 495px;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 40px;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const PageTitleDiv = styled.div`
  text-align: center;
`;

const PageSubTitleSpan = styled.span`
  font-weight: normal;
  font-size: 34px;
  margin: 0;
  padding: 0;
`;

const PageTitle = styled.h4`
  font-size: 42px;
  line-height: 48px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CommonP = styled.p`
  font-size: 22px;
  max-width: 50%;
  margin: 16px auto 0 auto;
  line-height: 30px;
  padding: 0;
`;

const UserGuideContentDiv = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;
`;

const UserGuideContentItemDiv = styled.div`
  padding: 20px;
  @media (max-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
  @media (min-width: 768px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    max-width: ${(props) => (props.mdwidth / 12) * 100}%;
  }
  @media (min-width: 992px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    max-width: ${(props) => (props.lgwidth / 12) * 100}%;
  }
`;

const FeatureBoxDiv = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 4px;
  margin-top: 30px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
`;

const FeatureH5 = styled.h5`
  font-size: 28px;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
`;

const FeatureP = styled.p`
  color: rgb(255 255 255 / 50%);
  margin: 0;
  padding: 0;
`;

const LearnMoreButton = styled.button`
  padding: 0;
  font-weight: 500;
  text-transform: uppercase;
  color: #c59d5f;
  font-size: 12px;
  display: flex;
  transition: all 0.5s ease-in-out;
  background: transparent;
  border: none;
  box-shadow: none;
  margin-top: 20px;
  cursor: pointer;
`;

export default function Faq() {
  const navigate = useNavigate();

  const navigateTo = (to) => {
    navigate(to);
  };

  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <UserGuideSection id="faq">
      <CommonContainer>
        <PageTitleDiv>
          <PageTitle>
            <PageSubTitleSpan>Installation &amp; Setup</PageSubTitleSpan>
            <br />
            User Guides
          </PageTitle>
          <CommonP>
            From the Core Eloncoin wallet installation to a Masternode setup,
            select a user guide from the list below.
          </CommonP>
        </PageTitleDiv>
        <UserGuideContentDiv>
          <UserGuideContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureH5>Eloncoin Proof of stake</FeatureH5>
              <FeatureP>Introduction to ElonCoin Proof of Stake</FeatureP>
              <LearnMoreButton
                onClick={() =>
                  openNewTab(
                    'https://www.youtube.com/watch?v=zwy8xNEtYgU&feature=youtu.be'
                  )
                }
              >
                LEARN MORE
              </LearnMoreButton>
            </FeatureBoxDiv>
          </UserGuideContentItemDiv>
          <UserGuideContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureH5>Staking Requirements</FeatureH5>
              <FeatureP>What you need to Stake ElonCoin</FeatureP>
              <LearnMoreButton onClick={() => navigateTo('/staking-req')}>
                LEARN MORE
              </LearnMoreButton>
            </FeatureBoxDiv>
          </UserGuideContentItemDiv>
          <UserGuideContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureH5>Staking</FeatureH5>
              <FeatureP>How to start Staking</FeatureP>
              <LearnMoreButton onClick={() => navigateTo('/staking')}>
                LEARN MORE
              </LearnMoreButton>
            </FeatureBoxDiv>
          </UserGuideContentItemDiv>
          <UserGuideContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureH5>How and Where to Download Eloncoin Wallet</FeatureH5>
              <FeatureP>Where to get the latest Eloncoin Wallet</FeatureP>
              <LearnMoreButton onClick={() => navigateTo('/download')}>
                LEARN MORE
              </LearnMoreButton>
            </FeatureBoxDiv>
          </UserGuideContentItemDiv>
          <UserGuideContentItemDiv smwidth={12} mdwidth={12} lgwidth={6}>
            <FeatureBoxDiv>
              <FeatureH5>CLI API Documentation</FeatureH5>
              <FeatureP>Using eloncoin-cli</FeatureP>
              <LearnMoreButton onClick={() => navigateTo('/eloncoin-cli')}>
                LEARN MORE
              </LearnMoreButton>
            </FeatureBoxDiv>
          </UserGuideContentItemDiv>
        </UserGuideContentDiv>
      </CommonContainer>
    </UserGuideSection>
  );
}
