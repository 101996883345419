import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import HeaderComponent from '../../components/HeaderComponent';
import Faq from '../../components/Faq';
import Contactus from '../../components/Contactus';

import UpArrowImage from '../../assets/images/uparrow.png';
import MyWalletComponent from '../../components/MyWalletComponent';
import { AuthenticatedComponent } from '../../components/base/authenticated_component';

const PageLayout = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  float: left;
`;

const ScrollToTop = styled.button`
  opacity: 1;
  visibility: visible;
  display: inline-block;
  background-color: #b79155;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition:
    background-color 0.3s,
    opacity 0.5s,
    visibility 0.5s;
  box-shadow: none;
  border: none;
  z-index: 1000;
  text-decoration: none;
  cursor: pointer;
`;

const UpArrowImg = styled.img`
  height: 60%;
`;

export default function Wallet() {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [dispatch]);

  return (
    <AuthenticatedComponent>
      <PageLayout>
        <HeaderComponent />
        <MyWalletComponent />
        <Faq />
        <Contactus />
        {isVisible && (
          <ScrollToTop onClick={() => scrollToTop()}>
            <UpArrowImg src={UpArrowImage} />
          </ScrollToTop>
        )}
      </PageLayout>
    </AuthenticatedComponent>
  );
}
