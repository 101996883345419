import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../services/userService';
import Cookies from 'js-cookie';

export const login = createAsyncThunk(
  'auth/login',
  async ({ data }, thunkAPI) => {
    try {
      const res = await UserService.login(data);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async ({ data }, thunkAPI) => {
    try {
      const res = await UserService.signup(data);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAccount = createAsyncThunk(
  'user/update',
  async ({ data }, thunkAPI) => {
    try {
      const res = await UserService.update(data);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/change-password',
  async ({ data }, thunkAPI) => {
    try {
      const res = await UserService.changePassword(data);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const listUsers = createAsyncThunk(
  'user/list',
  async ({ data }, thunkAPI) => {
    try {
      const res = await UserService.getUsers(data);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getHistory = createAsyncThunk(
  'user/list',
  async ({ username, data }, thunkAPI) => {
    try {
      const res = await UserService.getHistory({ username, data });
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBearerToken = (accessToken, expires) => {
  if (expires?.indexOf('m') > -1) {
    expires = parseInt(expires) / 60 / 24;
  } else if (expires?.indexOf('d') > -1) {
    expires = parseInt(expires);
  } else if (expires?.indexOf('h') > -1) {
    expires = parseInt(expires) / 24;
  } else {
    expires = parseInt(expires) / 60 / 60 / 24;
  }
  Cookies.set('accessToken', accessToken, {
    expires,
  });
};

const initialState = {
  loading: false,
  user: null,
  expires: null,
  accessToken: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true,
        user: null,
        accessToken: null,
        expires: null,
      };
    case 'LOGIN_SUCCESS':
      updateBearerToken(
        action.payload?.accessToken ?? '',
        action.payload?.expires ?? ''
      );
      return {
        ...state,
        loading: false,
        accessToken: action.payload?.accessToken,
        expires: action.payload?.expires,
        user: action.payload?.user,
      };
    case 'UPDATE_SUCCESS':
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case 'LOG_OUT':
      return {
        ...state,
        loading: false,
        user: null,
        accessToken: null,
        expires: null,
      };
    default:
      return state;
  }
};

export default userReducer;
