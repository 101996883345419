import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import WarningImage from '../../assets/images/warning.gif';
import { AuthComponent } from '../base/auth_component';
import { signup } from '../../reducers/userReducer';
import { toast } from 'react-toastify';

const SignupSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const SignupContentDiv = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
`;

const SignupContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 60px;
  width: 50%;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const SignupContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
`;

const SignupContentFormLabel = styled.label`
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SignupContentFormInput = styled.input`
  height: 58px;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const HRLine = styled.hr`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SignupButton = styled.button`
  width: 100%;
  height: 58px;
  margin-top: 15px;
  font-size: 22px;
  cursor: pointer;
  background: transparent
    linear-gradient(284deg, #966118 0%, #e2ba76 49%, #966118 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: not-allowed !important;
  }
`;

const ErrorMessage = styled.p`
  font-family: Poppins, sans-serif;
  color: #ff0000;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
`;

const Loader = styled.div`
  margin-left: 10px;
  border-width: 6px;
  border-style: solid;
  border-color: #fff;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 2s infinite;
  animation: spin 2s infinite;

  &:before,
  &:after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: purple;
    position: absolute;
    left: 0.125rem;
  }

  &:before {
    top: 0.063rem;
  }

  &:after {
    bottom: 0.063rem;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AlertDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  color: red;
  margin-left: 10px;
  line-height: 14px;
  text-align: center;
  &&& {
    img {
      width: 13px;
    }
    span {
      font-size: 12px;
    }
    label {
      font-size: 10px;
    }
  }
`;

export default function SignupComponent() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);

  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const signupUser = async () => {
    if (password !== confirmPassword) {
      toast('Passwords do NOT match');
      return;
    }

    let data = {
      user_name: username.trim(),
      email: '',
      phone: '',
      address: address.trim(),
      password: password.trim(),
      email_verified: false,
    };
    try {
      const res = await dispatch(
        signup({
          data,
        })
      );

      if (signup.fulfilled.match(res)) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: res.payload });
        toast('You have logged in successfully!');
      } else {
        toast(res.payload.message);
      }
    } catch (error) {
      toast(error?.message);
    }
  };

  return (
    <AuthComponent>
      <SignupSection>
        <CommonContainer>
          <SignupContentDiv>
            <SignupContentFormDiv>
              <SignupContentFormTitle>Signup</SignupContentFormTitle>
              <SignupContentFormLabel>Username</SignupContentFormLabel>
              <SignupContentFormInput
                value={username}
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <SignupContentFormLabel>
                New ELONCHAIN Address
              </SignupContentFormLabel>
              <AlertDiv>
                <img src={WarningImage} alt="EMC" />
                <span> No old EMC address </span>
                <img src={WarningImage} alt="EMC" />
                <br />
                <label> EdKdLWUvcKvsJB... </label>
              </AlertDiv>
              <SignupContentFormInput
                value={address}
                placeholder="0xdb14..."
                autoComplete="address"
                onChange={(e) => setAddress(e.target.value)}
              />
              <SignupContentFormLabel>Password</SignupContentFormLabel>
              <SignupContentFormInput
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <SignupContentFormLabel>Confirm Password</SignupContentFormLabel>
              <SignupContentFormInput
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <HRLine />
              <SignupButton
                onClick={() => signupUser()}
                disabled={
                  username === '' ||
                  address === '' ||
                  password === '' ||
                  confirmPassword === ''
                }
              >
                Signup
                {loading && <Loader />}
              </SignupButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </SignupContentFormDiv>
          </SignupContentDiv>
        </CommonContainer>
      </SignupSection>
    </AuthComponent>
  );
}
