import React from 'react';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import DownloadBannerImage from '../../assets/images/planets.png';

const DownloadBannerSection = styled.section`
  background: url(${StarBackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  padding: 150px 0 0 0 !important;
  min-height: 500px;
  &:after {
    content: '';
    position: absolute;
    background: #0000009e;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
    top: -4px;
  }
  @media only screen and (max-width: 991px) {
    padding: 100px 0 60px 0 !important;
  }
  @media only screen and (max-width: 767px) {
    padding: 100px 0 0 0 !important;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const DownloadBannerContent = styled.div`
  position: relative;
  z-index: 1;
`;

const DownloadBannerContentDiv = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
  align-items: center;
  justify-content: space-evenly;
`;

const DownloadBannerContentItemDiv = styled.div`
  @media (max-width: 767px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.width / 12) * 100}%;
    flex: 0 0 ${(props) => (props.width / 12) * 100}%;
    max-width: ${(props) => (props.width / 12) * 100}%;
  }
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
`;

const CommonH1 = styled.h1`
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 15px;
  @media only screen and (max-width: 991px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const DownloadTitleSpan = styled.span`
  font-weight: normal;
  font-size: 34px;
`;

const DownloadBannerP = styled.p`
  font-family: Poppins, sans-serif;
  line-height: 24px;
  font-size: 16px;
  overflow-x: hidden;
  color: #fff;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 1rem;
`;

const DownloadBannerImg = styled.img`
  max-width: 400px;
  @media (max-width: 767px) {
    max-width: 280px;
    margin-top: 20px;
  }
`;

export default function DownloadBanner() {
  return (
    <DownloadBannerSection>
      <CommonContainer>
        <DownloadBannerContent>
          <DownloadBannerContentDiv>
            <DownloadBannerContentItemDiv width={12} smwidth={6}>
              <CommonH1>
                <DownloadTitleSpan>ElonCoin</DownloadTitleSpan>
                <br />
                Download
              </CommonH1>
              <DownloadBannerP>
                The latest stable release v0.1.0 was released on 24th Jan.
              </DownloadBannerP>
            </DownloadBannerContentItemDiv>
            <DownloadBannerContentItemDiv width={12} smwidth={6}>
              <DownloadBannerImg src={DownloadBannerImage} />
            </DownloadBannerContentItemDiv>
          </DownloadBannerContentDiv>
        </DownloadBannerContent>
      </CommonContainer>
    </DownloadBannerSection>
  );
}
