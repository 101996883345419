import React from 'react';
import styled from 'styled-components';

import SilverBackgroundImage from '../../assets/images/silverBg.png';
import StakingImage1 from '../../assets/images/1.png';
import StakingImage2 from '../../assets/images/2.png';
import RewardsSiteImage from '../../assets/images/logo_3.0@2x.png';

const StakingComponentSection = styled.section`
  background-image: url(${SilverBackgroundImage});
  color: #000;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const StakingComponentContent = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
`;

const CommonP = styled.p`
  font-size: 16px;
  color: #000;
`;

const StakingImg = styled.img`
  max-width: 100%;
  height: auto;
`;

const StakingTitle = styled.h6`
  margin-top: 50px;
  font-size: 20px;
`;

const StakingP = styled.p`
  margin-bottom: 16px;
`;

const StakingPQuote = styled.p`
  margin-bottom: 16px;
  color: rgb(108, 108, 109);
  background: rgb(239, 239, 239);
  border-color: rgba(40, 41, 46, 0.35) rgba(40, 41, 46, 0.35)
    rgba(40, 41, 46, 0.35) rgb(197, 157, 95);
  border-radius: 4px;
  width: 100%;
  display: block;
  padding-left: 5px;
`;

const RewardsSiteDiv = styled.div`
  display: flex;
`;

const RewardsSiteImg = styled.img`
  width: 112px;
  height: 112px;
`;

const RewardsSiteContentDiv = styled.div`
  margin-left: 10px;
`;

const RewardsSiteContentTitle = styled.p`
  margin: 0px;
  padding: 0px;
  text-rendering: optimizelegibility;
  color: rgb(0, 0, 0);
  text-decoration-line: underline;
  transition: all 300ms ease-in-out 0s;
  font-size: 12px;
`;

const RewardsSiteContentLink = styled.p`
  margin: 0px;
  padding: 0px;
  text-rendering: optimizelegibility;
  color: rgb(0, 0, 0);
  transition: all 300ms ease-in-out 0s;
  font-size: 12px;
`;

const RewardsSiteContentP = styled.p`
  color: rgb(108, 108, 109);
  background: rgb(248, 250, 252);
  border-color: rgba(40, 41, 46, 0.35) rgba(40, 41, 46, 0.35)
    rgba(40, 41, 46, 0.35) rgb(197, 157, 95);
  font-size: 12px;
`;

const RewardsSiteContentLinkDiv = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RewardsSiteContentLinkImg = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 2px;
`;

export default function StakingComponent() {
  return (
    <StakingComponentSection id="wallet">
      <CommonContainer>
        <StakingComponentContent>
          <CommonP>
            1. Make sure your wallet is completely setup and synchronized.
          </CommonP>
          <CommonP>
            2. The ElonCoin you have sent to your wallet needs to have a minimum
            of 600 confirmations.
          </CommonP>
          <CommonP>
            3. In the ElonCoin Core client, click the LOCK icon and select
            Staking Only.
          </CommonP>
          <StakingImg src={StakingImage1} />
          <CommonP>4. Enter your password and confirm.</CommonP>
          <CommonP>
            5. That's it your ElonCoin will now stake. and a successful staking
            reward will look something like this:
          </CommonP>
          <StakingImg src={StakingImage2} />
          <StakingTitle>If you are using ElonCoin via CLI:</StakingTitle>
          <StakingP>
            Simply navigate to where your ElonCoin-Daemon directory is and run
            the following command to unlock it for staking:
          </StakingP>
          <StakingPQuote>./eloncoin-cli walletpassphrase 0 true</StakingPQuote>
          <StakingP>
            To see if your your wallet is staking correctly you can run the
            following command after a few minutes to see if the status for
            staking is true:
          </StakingP>
          <StakingPQuote>./eloncoin-cli getstakingstatus</StakingPQuote>
          <StakingP>
            To get a rough estimate of your staking interest visit our rewards
            site:
          </StakingP>
          <RewardsSiteDiv>
            <RewardsSiteImg src={RewardsSiteImage} />
            <RewardsSiteContentDiv>
              <RewardsSiteContentTitle>
                Masternodes - ElonCoin's Advanced Second Layer Network
              </RewardsSiteContentTitle>
              <RewardsSiteContentP>
                ElonCoin Masternodes are optimally designed to ensure best
                community governance on the blockchain and provide advanced
                services on top of the tier two network.
              </RewardsSiteContentP>
              <RewardsSiteContentLinkDiv>
                <RewardsSiteContentLinkImg src={RewardsSiteImage} />
                <RewardsSiteContentLink>
                  rewards.elon.org
                </RewardsSiteContentLink>
              </RewardsSiteContentLinkDiv>
            </RewardsSiteContentDiv>
          </RewardsSiteDiv>
        </StakingComponentContent>
      </CommonContainer>
    </StakingComponentSection>
  );
}
