import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

export const AdminAuthenticatedComponent = ({ children, username }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    if (user.role !== 'admin' && username && username !== user.user_name) {
      navigate('/');
      return;
    }
  }, [user, navigate, username]);

  if (user) {
    return <>{children}</>;
  }

  return null;
};
