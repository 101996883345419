import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import Login from './pages/login';
import Signup from './pages/signup';
import MyAccount from './pages/myaccount';
import Settings from './pages/settings';
import UserGuide from './pages/userguide';
import StakingPage from './pages/staking';
import StakingReqPage from './pages/stakingreq';
import Download from './pages/download';
import ElonCoinCliPage from './pages/eloncoincli';
import Privacy from './pages/privacy';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wallet from './pages/wallet';
import Swap from './pages/swap';
import History from './pages/history';
// import NotFound from './pages/notfound';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/myaccount" element={<MyAccount />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/user-guide" element={<UserGuide />} />
          <Route path="/staking" element={<StakingPage />} />
          <Route path="/staking-req" element={<StakingReqPage />} />
          <Route path="/download" element={<Download />} />
          <Route path="/eloncoin-cli" element={<ElonCoinCliPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/history/:username" element={<History />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
