export const formatAddress = (address, start, end) => {
  return `${address.substring(0, start)}...${address.substring(end)}`;
};

export const formatNumber = (value, count) => {
  if (value === null || value === undefined || isNaN(value)) {
    return '0';
  }
  try {
    return parseFloat(value)
      .toLocaleString(undefined, {
        minimumFractionDigits: count || 2,
        maximumFractionDigits: count || 2,
      })
      .replace(/\.?0+$/, '');
  } catch (error) {
    return '-';
  }
};

export const formatBigNumber = (value, count) => {
  if (value < 1e6) {
    return formatNumber(value, count);
  } else if (value < 1e9) {
    return `${formatNumber(value / 1e6, count)}M`;
  } else if (value < 1e12) {
    return `${formatNumber(value / 1e9, count)}B`;
  } else {
    return `${formatNumber(value / 1e12, count)}T`;
  }
};
