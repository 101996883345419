import React from 'react';
import styled from 'styled-components';

import SilverBackgroundImage from '../../assets/images/silverBg.png';

const StakingReqComponentSection = styled.section`
  background-image: url(${SilverBackgroundImage});
  color: #000;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const StakingReqComponentContent = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
`;

const CommonH1Black = styled.h1`
  font-size: 30px;
  line-height: 48px;
  margin-bottom: 30px;
  color: #000;
  @media only screen and (max-width: 991px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const StakingReqList = styled.ul`
  list-style-type: disc;
`;

const StakingReqItems = styled.li``;

export default function StakingReqComponent() {
  return (
    <StakingReqComponentSection id="wallet">
      <CommonContainer>
        <StakingReqComponentContent>
          <CommonH1Black>
            Before you can stake your ElonCoin you will need to meet all of
            these requirements:
          </CommonH1Black>
          <StakingReqList>
            <StakingReqItems>
              A ElonCoin balance exceeding 0 ElonCoin with at least 600
              confirmations.
            </StakingReqItems>
            <StakingReqItems>
              A ElonCoin wallet running the latest ElonCoin release and
              synchronized to the latest block.
            </StakingReqItems>
            <StakingReqItems>
              The wallet needs to be unlocked for staking.
            </StakingReqItems>
            <StakingReqItems>
              The wallet also needs to be open and connected to the internet
              24/7 to stake.
            </StakingReqItems>
            <StakingReqItems>
              For cold staking your staking node needs to meet all above
              conditions and has whitelisted it's delegation for staking.
            </StakingReqItems>
          </StakingReqList>
        </StakingReqComponentContent>
      </CommonContainer>
    </StakingReqComponentSection>
  );
}
