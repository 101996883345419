import React from 'react';
import styled from 'styled-components';

import SilverBackgroundImage from '../../assets/images/silverBg.png';
import MobileImage from '../../assets/images/mobile-wallet.png';

const MobileWalletSection = styled.section`
  background-image: url(${SilverBackgroundImage});
  color: #000;
  margin-top: 20px;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const MobileWalletContent = styled.div`
  position: relative;
  z-index: 1;
`;

const MobileWalletTextDiv = styled.div`
  position: relative;
  padding: 100px 0;
  margin-top: 270px;
  @media (max-width: 767px) {
    position: relative;
    padding: 140px 0 140px 0;
  }
`;

const MobileWalletContentDiv = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  margin-right: 15px;
  margin-left: 15px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const MobileWalletContentItemDiv = styled.div`
  @media (min-width: 576px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.smwidth / 12) * 100}%;
    max-width: ${(props) => (props.smwidth / 12) * 100}%;
  }
  @media (min-width: 768px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.mdwidth / 12) * 100}%;
    max-width: ${(props) => (props.mdwidth / 12) * 100}%;
  }
  @media (min-width: 992px) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    flex: 0 0 ${(props) => (props.lgwidth / 12) * 100}%;
    max-width: ${(props) => (props.lgwidth / 12) * 100}%;
  }
`;

const CommonH1Black = styled.h1`
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 15px;
  color: #000;
  @media only screen and (max-width: 991px) {
    font-size: 26px;
    line-height: 34px;
  }
`;

const CommonPBlack = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bolder;
  color: #000;
`;

const CommonButtonsBlack = styled.button`
  background: #000;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  padding: 9px 16px;
  font-weight: 200;
  @media only screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const MobileWalletImgDiv = styled.div`
  @media (min-width: 767px) {
    position: relative;
    right: 0;
    top: 28px;
    max-width: 100%;
    min-width: auto;
    z-index: 9;
    transform: none;
  }
  @media (min-width: 1024px) {
    top: 0px;
    max-width: 716px;
    min-width: 400px;
    z-index: 9;
  }
`;

const MobileWalletImg = styled.img`
  width: 100%;
`;

export default function MobileWallet() {
  return (
    <MobileWalletSection>
      <CommonContainer>
        <MobileWalletContent>
          <MobileWalletTextDiv>
            <MobileWalletContentDiv>
              <MobileWalletContentItemDiv smwidth={12} mdwidth={5} lgwidth={6}>
                <CommonH1Black>ElonCoin Mobile Wallet</CommonH1Black>
                <CommonPBlack>
                  Download the latest ElonCoin mobile wallet for your Apple or
                  Android device.
                </CommonPBlack>
                <CommonButtonsBlack>Download wallet</CommonButtonsBlack>
              </MobileWalletContentItemDiv>
              <MobileWalletContentItemDiv smwidth={12} mdwidth={7} lgwidth={6}>
                <MobileWalletImgDiv>
                  <MobileWalletImg src={MobileImage} />
                </MobileWalletImgDiv>
              </MobileWalletContentItemDiv>
            </MobileWalletContentDiv>
          </MobileWalletTextDiv>
        </MobileWalletContent>
      </CommonContainer>
    </MobileWalletSection>
  );
}
