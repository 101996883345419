import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

export const AuthComponent = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      if (user.role === 'admin') navigate('/swap');
      else navigate('/wallet');
    }
  }, [user, navigate]);
  return <>{children}</>;
};
