import axios from 'axios';
import Cookies from 'js-cookie';

// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && {
        ...error.response.data,
        message: error.response.data.message || 'Something went wrong',
      }) || {
        message: 'Could not connect to server',
      }
    )
);

axiosInstance.interceptors.request.use((config) => {
  const accessToken = Cookies.get('accessToken');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default axiosInstance;
