import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import QrCard from '../QrCardComponent';
import Modal from 'styled-react-modal';
import { formatBigNumber } from '../../utils/utils';
import {
  getAddressInfo,
  getNewCoinBalance,
  getOldCoinBalance,
} from '../../reducers/rpcReducer';
import { ethers } from 'ethers';
import { useAdminSwapperContract } from '../../hooks/useContract';
import { updateAccount } from '../../reducers/userReducer';

const MyWalletSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 70vh;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const MyWalletContentDiv = styled.div`
  padding: 50px;
  position: relative;
  z-index: 1;
`;

const MyWalletContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 20px;
  width: 90%;
  max-width: 600px;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const MyWalletContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
`;

const MyWalletContentFormLabel = styled.label`
  display: inline-block;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-weight: 800;
`;

const MyWalletContentFormInput = styled.input`
  height: 58px;
  display: block;
  width: 100%;
  padding: 0px;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const MyNewWalletContentFormInput = styled.input`
  height: 58px;
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  background-color: #00000044;
  background-clip: padding-box;
  border: 1px solid #ffffff55;

  border-top: 2px solid rgba(255, 255, 255, 0.5);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const WalletActionsContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 55px;
`;

const WalletCardsContainer = styled.div`
  justify-content: center;
  align-items: center;
`;

const WalletCard = styled.div`
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: #69440bbb;
  position: relative;
  flex: 1;
  min-height: 150px;
`;

const NewWalletCard = styled.div`
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: #c59d5f99;
  position: relative;
  flex: 1;
  min-height: 150px;
`;

const IconContainer = styled.span`
  cursor: pointer;
  margin: 2px;
`;

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  `;

const MyWalletDescription = styled.span`
  display: block;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-size: 0.7rem;
`;

const UpdateButton = styled.button`
  float: right;
  margin-top: 10px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #69440b;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition:
    background-color 0.3s,
    opacity 0.5s,
    visibility 0.5s;
`;

export default function MyWalletComponent() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [copied, setCopied] = useState(false);
  const [newCopied, setNewCopied] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [newBalance, setNewBalance] = useState(null);
  const [oldBalance, setOldBalance] = useState(null);
  const adminSwapperContract = useAdminSwapperContract();
  const [swapped, setSwapped] = useState(0);
  const [scriptPubKey, setScriptPubkey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(user.address);

  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if ((adminSwapperContract, scriptPubKey)) {
      adminSwapperContract
        .swappedAmountByReceiverPubKey(scriptPubKey)
        .then((res) => {
          setSwapped(ethers.utils.formatUnits(res, 8));
        })
        .catch((err) => {
          console.log(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [adminSwapperContract, scriptPubKey]);

  const getAddressInfomation = useCallback(
    async (address) => {
      try {
        const res = await dispatch(
          getAddressInfo({
            address,
          })
        );

        if (getAddressInfo.fulfilled.match(res)) {
          const oldPubKey = res.payload?.info?.scriptPubKey ?? null;
          setScriptPubkey(ethers.utils.hexZeroPad(`0x${oldPubKey}`, 32));
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          setScriptPubkey(null);
        }
      } catch (error) {
        setScriptPubkey(null);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (user.oldAddress) {
      getAddressInfomation(user.oldAddress);
    }
  }, [user.oldAddress, getAddressInfomation]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        toast('Old Eloncoin address has been copied!');
      })
      .catch((err) => {
        toast(err.message);
      });
  };

  const copyNewToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setNewCopied(true);
        setTimeout(() => {
          setNewCopied(false);
        }, 2000);
        toast('New Elonchain address has been copied!');
      })
      .catch((err) => {
        toast(err.message);
      });
  };

  const getNewBalance = useCallback(
    async (address) => {
      try {
        const res = await dispatch(
          getNewCoinBalance({
            address,
          })
        );

        if (getNewCoinBalance.fulfilled.match(res)) {
          setNewBalance(res.payload);
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          setNewBalance(0);
        }
      } catch (error) {
        setNewBalance(0);
      }
    },
    [dispatch]
  );

  const getOldBalance = useCallback(
    async (address) => {
      try {
        const res = await dispatch(
          getOldCoinBalance({
            address,
          })
        );

        if (getOldCoinBalance.fulfilled.match(res)) {
          setOldBalance(res.payload);
        } else {
          if (res.payload.statusCode === 401) {
            dispatch({ type: 'LOG_OUT' });
          }
          setOldBalance(0);
        }
      } catch (error) {
        setOldBalance(0);
      }
    },
    [dispatch]
  );

  const handleUpdateButtonClick = async () => {
    let data = {
      address: address,
    };
    try {
      const res = await dispatch(
        updateAccount({
          data,
        })
      );

      if (updateAccount.fulfilled.match(res)) {
        dispatch({ type: 'UPDATE_SUCCESS', payload: res.payload });
        toast('New ElonChain address has been updated successfully!');
      } else {
        if (res.payload.statusCode === 401) {
          dispatch({ type: 'LOG_OUT' });
        }
        toast(res.payload.message);
      }
    } catch (error) {
      toast(error?.message);
    }
  };

  useEffect(() => {
    if (user.address) {
      getNewBalance(user.address);
    } else {
      setNewBalance(0);
    }
  }, [user.address, getNewBalance]);

  useEffect(() => {
    if (user.oldAddress) {
      getOldBalance(user.oldAddress);
    } else {
      setOldBalance(0);
    }
  }, [user.oldAddress, getOldBalance]);

  return (
    <MyWalletSection>
      <CommonContainer>
        <MyWalletContentDiv>
          <MyWalletContentFormDiv>
            <MyWalletContentFormTitle>My Wallet</MyWalletContentFormTitle>
            <WalletCardsContainer>
              <WalletCard>
                <MyWalletContentFormLabel>
                  Old ElonCoin
                </MyWalletContentFormLabel>
                <MyWalletContentFormInput
                  defaultValue={user.oldAddress}
                  disabled
                />
                <MyWalletContentFormLabel>
                  {loading
                    ? '0 EMC'
                    : `${formatBigNumber(oldBalance - swapped)} EMC`}
                </MyWalletContentFormLabel>
                <MyWalletDescription>
                  Deposit to this address to get new EMC coin
                </MyWalletDescription>
                <WalletActionsContainer>
                  <IconContainer
                    title="Copy"
                    onClick={() => {
                      copyToClipboard(user.oldAddress);
                    }}
                  >
                    {copied ? (
                      <Icon icon="material-symbols:check" />
                    ) : (
                      <Icon icon="material-symbols:content-copy" />
                    )}
                  </IconContainer>
                  <IconContainer title="QR Scan" onClick={toggleModal}>
                    <Icon icon="mdi:qrcode-scan" />
                  </IconContainer>
                </WalletActionsContainer>
              </WalletCard>
              <NewWalletCard>
                <MyWalletContentFormLabel>
                  New ElonChain
                </MyWalletContentFormLabel>
                <MyNewWalletContentFormInput
                  defaultValue={user.address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <MyWalletContentFormLabel>
                  {`${formatBigNumber(newBalance)} EMC`}
                </MyWalletContentFormLabel>
                <UpdateButton onClick={() => handleUpdateButtonClick()}>
                  Update
                </UpdateButton>
                <WalletActionsContainer>
                  <IconContainer
                    title="Copy"
                    onClick={() => {
                      copyNewToClipboard(user.address);
                    }}
                  >
                    {newCopied ? (
                      <Icon icon="material-symbols:check" />
                    ) : (
                      <Icon icon="material-symbols:content-copy" />
                    )}
                  </IconContainer>
                </WalletActionsContainer>
              </NewWalletCard>
            </WalletCardsContainer>
          </MyWalletContentFormDiv>
        </MyWalletContentDiv>
      </CommonContainer>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        <QrCard url={user.oldAddress} />
      </StyledModal>
    </MyWalletSection>
  );
}
