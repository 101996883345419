import { useMemo } from 'react';
import { useProvider } from './useProvider';
import { AdminSwapperContract } from '../services/contracts/adminSwapperContract/adminSwapperContract';

export const useAdminSwapperContract = () => {
  const provider = useProvider();
  return useMemo(
    () =>
      provider
        ? new AdminSwapperContract(
            process.env.REACT_APP_ADMIN_SWAPPER_CONTRACT,
            provider
          )
        : null,
    [provider]
  );
};
