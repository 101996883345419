import React from 'react';
import styled from 'styled-components';

import Accordion from '../Accordion';

import StarBackgroundImage from '../../assets/images/starbg.png';

const accordionData = [
  {
    title: 'What is Eloncoin?',
    content:
      "Eloncoin is a fan-inspired cryptocoin after Elon. Each block is 71 seconds represented after Elon on a 71 in the 1900s. Eloncoin isn't a token or a smart contract, but a cryptocoin like dogecoin. Eloncoin is based upon an algorithm consensus of POS, which stands for Proof of stake. A user can validate or mine blocks transactions based upon how many coins they hodl. Compared to other methodologies such as Bitcoin - POW (Proof of Work), some Bitcoin miners have rooms that consist of 1000s of ASICs, all calculating away for a block, which uses tremendous power. Eloncoin doesn't need a room full of 1000s of ASICs, GPUs/CPUs maxed out for mining. Running the eloncoin wallet client is very light and uses a tiny fraction of power in comparison.",
  },
  {
    title: 'What advantages does Eloncoin offer?',
    content:
      'Elon is looking for cryptocurrencies that use <1% of the energy/transaction compared to Bitcoin. Comparing between a user with a room of 1000 ASICs and an eloncoin wallet running on a laptop, you are going from millions of wattage usage to as much as a couple of watts of power to run the eloncoin client, cutting out more than 99.99%+ power in comparison to those Bitcoin Miners.',
  },
  {
    title: 'Why Eloncoin?',
    content:
      'Dogecoin in the future may be seen as pocket change at $1/each, Elon tweeted on Doge on many occasions. Dogecoin as pocket change, eloncoin as bills, HODL for mars!',
  },
  {
    title: 'What is planned for Eloncoin?',
    content:
      'A mobile wallet is planned for the future. Our goal is to get eloncoin to as many users as possible. Airdrop to as many as possible, get onto more exchanges and go to mars. There are more contests planned for eloncoin in the future. Stay tuned!',
  },
];

const FaqSection = styled.section`
  background-image: url(${StarBackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 150px;
  padding: 100px 0;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const PageTitleDiv = styled.div`
  text-align: center;
`;

const PageTitle = styled.h4`
  font-size: 42px;
  line-height: 48px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CommonP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bolder;
`;

const AccordionDiv = styled.div`
  max-width: 600px;
  margin: 2rem auto;
`;

export default function Faq() {
  return (
    <FaqSection id="faq">
      <CommonContainer>
        <PageTitleDiv>
          <PageTitle>Frequently Asked Questions</PageTitle>
          <CommonP>Eloncoin questions and answers.</CommonP>
          <AccordionDiv>
            {accordionData.map(({ title, content }, index) => (
              <Accordion key={index} title={title} content={content} />
            ))}
          </AccordionDiv>
        </PageTitleDiv>
      </CommonContainer>
    </FaqSection>
  );
}
