import axiosInstance from '../utils/axios';

const login = async (data) => {
  const res = await axiosInstance.post(`/auth/login`, data);
  return Promise.resolve(res.data);
};

const signup = async (data) => {
  const res = await axiosInstance.post(`/auth/signup`, data);
  return Promise.resolve(res.data);
};

const update = async (data) => {
  const res = await axiosInstance.put(`/user`, data);
  return Promise.resolve(res.data);
};

const changePassword = async (data) => {
  const res = await axiosInstance.post(`/auth/change-password`, data);
  return Promise.resolve(res.data);
};

const getUsers = async (data) => {
  const res = await axiosInstance.get(`/user/list`, { params: data });
  return Promise.resolve(res.data);
};

const getHistory = async ({ username, data }) => {
  const res = await axiosInstance.get(`/user/swap-history/${username}`, {
    params: data,
  });
  return Promise.resolve(res.data);
};

const UserService = {
  login,
  signup,
  update,
  getUsers,
  changePassword,
  getHistory,
};

export default UserService;
