import React from 'react';
import styled from 'styled-components';

import StarBackgroundImage from '../../assets/images/starbg.png';
import ChatImage from '../../assets/images/hand2.png';

const ChatSectionDiv = styled.div`
  padding: 100px 0 0 0;
  background-image: url(${StarBackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 600px;
  @media only screen and (max-width: 991px) {
    padding-top: 40px;
  }
  @media (max-width: 992px) {
    padding-bottom: 495px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const CountDiv = styled.div`
  text-align: center;
  position: relative;
`;

const CommonH4 = styled.h4`
  font-size: 42px;
  line-height: 48px;
  margin-bottom: 30px;
  font-weight: bold;
`;

const CommonP = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bolder;
`;

const ChatImgDiv = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  bottom: -36px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 767px) {
    position: relative;
  }
`;

const ChatImg = styled.img`
  max-width: 594px;
  margin: 0 auto;
  position: relative;
  bottom: -125px;
  @media (max-width: 992px) {
    max-width: 400px;
    bottom: 0;
  }
  @media (max-width: 767px) {
    max-width: 280px;
  }
`;

export default function ChatSection() {
  return (
    <ChatSectionDiv id="chatsection">
      <CommonContainer>
        <CountDiv>
          <CommonH4>Power Efficient</CommonH4>
          <CommonP>Uses up to 99.9%+ less power compared to Bitcoin</CommonP>
          <ChatImgDiv>
            <ChatImg src={ChatImage} />
          </ChatImgDiv>
        </CountDiv>
      </CommonContainer>
    </ChatSectionDiv>
  );
}
