import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import Modal from 'styled-react-modal';
import { getHistory } from '../../reducers/userReducer';
import HistoryItemComponent from '../HistoryItemComponent';
import { useAdminSwapperContract } from '../../hooks/useContract';

const HistoriesSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
  display_: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const HistoriesContentDiv = styled.div`
  padding: 50px;
  position: relative;
  z-index: 1;
`;

const HistoriesContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 20px;
  width: 90%;
  max-width: 600px;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const HistoriesContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
  display: inline-block;
  margin: 0px;
`;

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  `;

const HistoryTable = styled.table`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-collapse: collapse;
  &&& {
    td {
      background-color: #69440b20;
    }
    tr:nth-child(even) {
      background-color: #69440b70;
    }
  }
`;

const HistoryTh = styled.th`
  text-align: ${(props) =>
    props.cellalign !== null ? props.cellalign : 'left'};
  padding: 8px;
`;

const HistoryTr = styled.tr`
  background-color: #69440bbb;
`;

const PaginationDiv = styled.div`
  margin-top: 10px;
  display: block;
  &&& {
    ul li {
      min-width: 30px;
      text-align: center;
      display: inline-block;
      border-radius: 2px;
      background: #00000050;
      cursor: pointer;
    }
    ul li:hover {
      background: #ffffff50;
    }
    ul li.selected {
      background: #ffffff50;
    }
    ul li a {
      padding: 8px;
      display: block;
    }
  }
`;

export default function HistoryComponent({ username }) {
  const pageSize = 10;
  const dispatch = useDispatch();
  const adminSwapperContract = useAdminSwapperContract();

  const [isOpen, setIsOpen] = useState(false);
  const [histories, seHistories] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [oldAddress, setOldAddress] = useState('');

  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  const handlePageClick = (event) => {
    setPageNumber(event.selected);
  };

  const getSwapHistory = useCallback(async () => {
    try {
      let data = {
        pageSize,
        pageNumber,
      };

      const res = await dispatch(getHistory({ data, username }));
      setPageCount(res.payload?.totalPages ?? 0);
      setOldAddress(res.payload?.oldAddress ?? 0);
      if (getHistory.fulfilled.match(res)) {
        seHistories(res.payload?.swaps ?? []);
      } else {
        if (res.payload.statusCode === 401) {
          dispatch({ type: 'LOG_OUT' });
        }
      }
    } catch (error) {
      console.log(error?.message);
    }
  }, [dispatch, pageNumber, username]);

  useEffect(() => {
    getSwapHistory();
  }, [pageNumber, getSwapHistory]);

  return (
    <HistoriesSection>
      <CommonContainer>
        <HistoriesContentDiv>
          <HistoriesContentFormDiv>
            <HistoriesContentFormTitle>History</HistoriesContentFormTitle>

            <HistoryTable>
              <thead>
                <HistoryTr>
                  <HistoryTh cellalign="left">User Name</HistoryTh>
                  <HistoryTh cellalign="left">Old Address</HistoryTh>
                  <HistoryTh cellalign="left">New Address</HistoryTh>
                  <HistoryTh cellalign="left">Amount</HistoryTh>
                  <HistoryTh cellalign="left">Date</HistoryTh>
                </HistoryTr>
              </thead>
              <tbody>
                {histories.map((history, index) => (
                  <HistoryItemComponent
                    history={history}
                    username={username}
                    oldAddress={oldAddress}
                    key={history.transactionHash}
                    adminSwapperContract={adminSwapperContract}
                  />
                ))}
              </tbody>
            </HistoryTable>

            <PaginationDiv>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< prev"
                renderOnZeroPageCount={null}
              />
            </PaginationDiv>
          </HistoriesContentFormDiv>
        </HistoriesContentDiv>
      </CommonContainer>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      ></StyledModal>
    </HistoriesSection>
  );
}
