import React from 'react';
import styled from 'styled-components';

import SilverBackgroundImage from '../../assets/images/silverBg.png';

const ElonCoinCliComponentSection = styled.section`
  background-image: url(${SilverBackgroundImage});
  color: #000;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const ElonCoinCliComponentContent = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
`;

const CommonH1Black = styled.h1`
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: Poppins, sans-serif;
  line-height: 1.2;
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
`;

const CommonP = styled.p`
  margin-right: 0px;
  margin-bottom: 25px;
  margin-left: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  color: rgb(0, 0, 0);
  font-family: Poppins, sans-serif;
  font-size: 16px;
`;

const ElonCoinCliSpan = styled.span`
  margin: 0px;
  padding: 2px 5px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  border: 1px solid rgb(223, 223, 223);
  background-image: initial;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  display: inline-block;
  line-height: 14px;
  border-radius: 2px;
  background: #fff;
  font-size: 10px !important;
  color: #333;
`;

const ElonCoinCliPre = styled.pre`
  margin-bottom: 40px;
  padding: 5px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-size: 14px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background: rgb(239, 239, 239);
`;

const ElonCoinCliButton = styled.button`
  margin: 10px 0 30px 0;
  padding: 9px 16px;
  text-rendering: optimizelegibility;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  transition: all 0.5s ease-in-out 0s;
  white-space: nowrap;
  border-color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  cursor: pointer;
`;

const ElonCoinCliList = styled.ul`
  list-style-type: disc;
`;

const ElonCoinCliItems = styled.li``;

export default function ElonCoinCliComponent() {
  return (
    <ElonCoinCliComponentSection id="wallet">
      <CommonContainer>
        <ElonCoinCliComponentContent>
          <CommonP>
            Note that this appendix will not go into great depth on what
            eloncoin-cli is capable of. This should only be regarded as a source
            of inspiration and provide you with the basics to get started. You
            are encouraged to explore your node further yourself.
          </CommonP>
          <CommonH1Black>
            A.1 Communicating with{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>
          </CommonH1Black>
          <CommonP>
            When <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> starts, it also
            starts a web server that listens on TCP port{' '}
            <ElonCoinCliSpan>7107</ElonCoinCliSpan> by default. When you use{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>, it will connect to
            the web server and send your command to the web server over{' '}
            <ElonCoinCliSpan>http</ElonCoinCliSpan> and display relevant parts
            of the response to you.
          </CommonP>
          <CommonP>
            For example, suppose that you want to know the block id of the
            genesis block (the block at height 0) and issue the following
            command:
          </CommonP>
          <ElonCoinCliPre>$ ./eloncoin-cli getblockhash 0</ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> creates a http{' '}
            <ElonCoinCliSpan>POST</ElonCoinCliSpan> request with body:
          </CommonP>
          <ElonCoinCliPre>
            &#123;"method":"getblockhash","params":[0],"id":1&#125;
          </ElonCoinCliPre>
          <CommonP>
            and sends it to the web server that{' '}
            <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> runs. The{' '}
            <ElonCoinCliSpan>method</ElonCoinCliSpan> property of the request
            body is the command that you want to execute and the argument{' '}
            <ElonCoinCliSpan>0</ElonCoinCliSpan> is passed to the web server as
            an array with a single element.
          </CommonP>
          <CommonP>
            The web server processes the http request by looking up the block
            hash in the blockchain and replies with an http response with a body
          </CommonP>
          <ElonCoinCliPre>
            &#123;"result":"000000000019d6689c085ae165831e934ff763ae46a2a6c172b3f1b60a8ce26f","error":null,"id":"1"&#125;
          </ElonCoinCliPre>
          <CommonP>
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan> then displayed the
            value of the "result" property on your terminal.
          </CommonP>
          <ElonCoinCliPre>
            000000000019d6689c085ae165831e934ff763ae46a2a6c172b3f1b60a8ce26f
          </ElonCoinCliPre>
          <CommonP>
            This body of the http request follows a standard called JSON-RPC
            which describes how a client can call functions on a remote process
            using JSON, JavaScript Object Notation.
          </CommonP>
          <CommonH1Black>A.1.1 Using curl</CommonH1Black>
          <CommonP>
            Since the communication with{' '}
            <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> happens through{' '}
            <ElonCoinCliSpan>http</ElonCoinCliSpan>, any program capable of
            sending <ElonCoinCliSpan>http POST</ElonCoinCliSpan> requests, such
            as the command line tool <ElonCoinCliSpan>curl</ElonCoinCliSpan>,
            can be used to communicate with{' '}
            <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan>. However, to use other
            tools than <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>, you need
            to setup a username and password to use as authentication to the web
            server.’’
          </CommonP>
          <CommonP>
            Open, or create if non-existent, ElonCoin Core’s configuration file{' '}
            <ElonCoinCliSpan>~/.eloncoin/eloncoin.conf</ElonCoinCliSpan> and add
            the lines
          </CommonP>
          <CommonP>More parameters</CommonP>
          <CommonP>
            There are a lot of options in ElonCoin Core. Run{' '}
            <ElonCoinCliSpan>./eloncoind --help</ElonCoinCliSpan> to get a
            complete list.
          </CommonP>
          <ElonCoinCliPre>
            rpcuser=
            <br />
            rpcpassword=
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            When you have modified and saved the{' '}
            <ElonCoinCliSpan>~/.eloncoin/eloncoin.conf</ElonCoinCliSpan> file,
            you need to restart your node to make your changes effective.
          </CommonP>
          <CommonP>
            Now you can try to do getblockhash using curl (the backslash
            \character means that the command continues on the next line):
          </CommonP>
          <ElonCoinCliPre>
            curl --user kalle --data-binary \<br />
            '&#123;"method":"getblockhash","params":[0],"id":1&#125;' \<br />
            -H 'content-type: text/plain;' http://127.0.0.1:7107/
          </ElonCoinCliPre>
          <CommonP>Enter host password for user 'kalle':</CommonP>
          <ElonCoinCliPre>
            &#123;"result":"000000000019d6689c085ae165831e934ff763ae46a2a6c172b3f1b60a8ce26f","error":null,"id":1&#125;
          </ElonCoinCliPre>
          <CommonP>
            Remember to change the username from kalle to the username you
            configured in <ElonCoinCliSpan>eloncoin.conf</ElonCoinCliSpan>.
          </CommonP>
          <CommonP>
            This command will prompt you for the password. Enter the password
            and hit return. The reply from the web server will be the same as
            when you used <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan> but
            you will need to scan through the response body yourself to spot the
            result, which is the hash of block 0.
          </CommonP>
          <CommonH1Black>A.2 Graphical user interface</CommonH1Black>
          <CommonP>
            ElonCoin Core comes with a graphical user interface (GUI).
          </CommonP>
          <CommonP>
            This appendix mainly deals with the command-line interface{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan> for controlling and
            querying your running <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan>
            . But if you want to use ElonCoin Core as a ElonCoin wallet (and not
            just as a full node), it can be really useful to familiarize
            yourself with the GUI version. The GUI version of ElonCoin Core
            allows you to perform most common tasks that’s expected from a
            ElonCoin wallet, but to access the full set of features of ElonCoin
            Core you’ll need to use{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>.
          </CommonP>
          <CommonP>
            To use the GUI version of ElonCoin Core, you need to stop the
            current node and start the GUI version, called{' '}
            <ElonCoinCliSpan>eloncoin-qt:</ElonCoinCliSpan>
          </CommonP>
          <CommonP>Why "-qt"?</CommonP>
          <CommonP>
            The ElonCoin Core GUI is built using a GUI programming library
            called QT, hence the name, eloncoin-qt.
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli stop
            <br />
            ElonCoin server stopping
            <br />$ ./eloncoin-qt &
          </ElonCoinCliPre>
          <CommonP>
            If <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> didn’t have time
            to finish shutdown before you started
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan>, you will get an
            error message from <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan>.
            If so, click OK and try running
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> again in a few
            seconds.
          </CommonP>
          <CommonP>
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> uses the same data
            directory, ~/.eloncoin/, as eloncoind, which means that{' '}
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> will use the already
            downloaded and verified blockchain and the same wallet as eloncoind.
            It’s just the user interface that differs.
          </CommonP>
          <CommonP>
            By default, <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> will not
            start the web server to accept JSON-RPC requests as eloncoind does.
            So in order to use eloncoin-cli with{' '}
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan>, please start{' '}
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> as follows instead:
          </CommonP>
          <ElonCoinCliPre>$ ./eloncoin-qt -server &</ElonCoinCliPre>
          <CommonH1Black>
            A.3 Getting to know <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>
          </CommonH1Black>
          <CommonP>
            We have started ElonCoin Core in the background by running
          </CommonP>
          <ElonCoinCliPre>$ ./eloncoind &</ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The most important command to know is the{' '}
            <ElonCoinCliSpan>help</ElonCoinCliSpan> command. Run it without any
            arguments to get a list of all available commands:
          </CommonP>
          <ElonCoinCliPre> $ ./eloncoin-cli help</ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            You will get a pretty long list of commands grouped by subject, for
            example <ElonCoinCliSpan>Blockchain</ElonCoinCliSpan>,{' '}
            <ElonCoinCliSpan>Mining</ElonCoinCliSpan>, and{' '}
            <ElonCoinCliSpan>Wallet</ElonCoinCliSpan>. Some commands are
            somewhat self explanatory but if you want to know more about a
            specific command you can run <ElonCoinCliSpan>help</ElonCoinCliSpan>{' '}
            with the command name as argument. For example:{' '}
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli help getblockhash
            <br />
            getblockhash height
            <br />
            <br />
            Returns hash of block in best-block-chain at height provided.
            <br />
            <br />
            Arguments:
            <br />
            1. height (numeric, required) The height index
            <br />
            <br />
            Result:
            <br />
            "hash" (string) The block hash
            <br />
            <br />
            Examples:
            <br />
            &gt; eloncoin-cli getblockhash 1000
            <br />
            &gt; curl --user myusername --data-binary '&#123;"jsonrpc": "1.0",
            "id":"curltest", "method": "getblockhash", "params": [1000] &#125;'
            -H 'content-type: text/plain;' http://127.0.0.1:7107/
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            There are two ways to invoke{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>:
          </CommonP>
          <CommonH1Black>Using positional arguments</CommonH1Black>
          <CommonP>
            The meanings of the arguments are based on their relative positions.
            For example{' '}
            <ElonCoinCliSpan>./eloncoin-cli getblockhash 1000</ElonCoinCliSpan>.
            This is the most common way to use{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>
          </CommonP>
          <CommonH1Black>Using named arguments</CommonH1Black>
          <CommonP>
            The arguments are named on the command line. For example{' '}
            <ElonCoinCliSpan>
              ./eloncoin-cli -named getblockhash height=1000
            </ElonCoinCliSpan>
            . This is sometimes useful when the command takes optional arguments
            and you want to specify the second optional argument but not the
            first. We will see examples of this later.
          </CommonP>
          <CommonH1Black>A.4 Get to work</CommonH1Black>
          <CommonP>
            We will create an encrypted wallet and backup the wallet. Then we
            will receive some eloncoins and pass that money on to another
            address while dissecting the transactions for details. All using{' '}
            <ElonCoinCliSpan>eloncoin-cli</ElonCoinCliSpan>.
          </CommonP>
          <CommonH1Black>A.4.1 Create an encrypted wallet</CommonH1Black>
          <CommonP>
            When <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> (or{' '}
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan>) starts, it will
            automaticallycreate a wallet for you. However, this wallet is not
            encrypted, which means that its private keys and its seedare
            storedin clear on your hard drive. Let’s have a look at some data
            about the wallet:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli getwalletinfo
            <br />
            &#123;
            <br />
            "walletname": "wallet.dat",
            <br />
            "walletversion": 159900,
            <br />
            "balance": 0.00000000,
            <br />
            "unconfirmed_balance": 0.00000000,
            <br />
            "immature_balance": 0.00000000,
            <br />
            "txcount": 0,
            <br />
            "keypoololdest": 1527068317,
            <br />
            "keypoolsize": 1000,
            <br />
            "keypoolsize_hd_internal": 1000,
            <br />
            "paytxfee": 0.00000000,
            <br />
            "hdmasterkeyid": "aca4498fdaa5460528f9e842e8defa2d07bcf529"
            <br />
            &#125;
          </ElonCoinCliPre>
          <CommonP>
            The output from the <ElonCoinCliSpan>getwalletinfo</ElonCoinCliSpan>{' '}
            command shows us various informationabout the walletcurrently being
            used. The name of the wallet file is{' '}
            <ElonCoinCliSpan>wallet.dat</ElonCoinCliSpan>. This file isbydefault
            locatedin <ElonCoinCliSpan>~/.eloncoin/</ElonCoinCliSpan>. It
            contains the wallet seed, that’s used to derive key pairsasdiscussed
            in Chapter 4, but also some metadata about the wallet.
          </CommonP>
          <CommonP>
            The <ElonCoinCliSpan>balance</ElonCoinCliSpan> is how much confirmed
            eloncoins you have, while the
            <ElonCoinCliSpan>unconfirmed_balance</ElonCoinCliSpan> is the sum of
            incoming unconfirmed payments, notincluding confirmedpayments. The{' '}
            <ElonCoinCliSpan>immature_balance</ElonCoinCliSpan> is only relevant
            for miners, and denotes theamount ofnewly created eloncoins, that
            can’t be spent until after 100 blocks passed.
          </CommonP>
          <CommonP>
            Please refer to the help section on{' '}
            <ElonCoinCliSpan>getwalletinfo</ElonCoinCliSpan> for more details
            about the output.
          </CommonP>
          <CommonP>
            In order to create an encrypted wallet you need to create a new
            wallet using the command{' '}
            <ElonCoinCliSpan>encryptwallet</ElonCoinCliSpan>:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli -stdin encryptwallet
            <br />
            secretpassword
            <br />
            wallet encrypted; ElonCoin server stopping, restart to run with
            encrypted wallet. The keypool hasbeen flushed and a new HD seed was
            generated (if you are using HD). You need to make a newbackup.
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The above command command creates a new encrypted wallet. The{' '}
            <ElonCoinCliSpan>-stdin</ElonCoinCliSpan> optionis used toread the
            password argument from standard input, which in this case means that
            you type the password in yourterminal windowafter the command is
            started. End your input by hitting Enter and Ctrl-D. The reason of
            using <ElonCoinCliSpan> -stdin</ElonCoinCliSpan> is that we don’t
            want the password to be written in the command itself becausemost
            shell interpreters,such as bash, keeps a history of commands in a
            file. The <ElonCoinCliSpan>-stdin</ElonCoinCliSpan> option
            ensuresthat thepassword does not end up in any such history files.
          </CommonP>
          <CommonP>
            It’s important to create a new encrypted wallet instead of just
            encrypting the already existing wallet. This isbecause the old
            wallet may already have been compromised on your hard drive. As
            noted by the output,
            <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> has stopped. ElonCoin
            Core can’t currently switch to a new wallet filewhilerunning. Let’s
            start <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> again and look at
            the wallet:
          </CommonP>
          <ElonCoinCliPre>
            &#123;
            <br />
            "walletname": "wallet.dat",
            <br />
            "walletversion": 159900,
            <br />
            "balance": 0.00000000,
            <br />
            "unconfirmed_balance": 0.00000000,
            <br />
            "immature_balance": 0.00000000,
            <br />
            "txcount": 0,
            <br />
            "keypoololdest": 1527068552,
            <br />
            "keypoolsize": 1000,
            <br />
            "keypoolsize_hd_internal": 1000,
            <br />
            "unlocked_until": 0,
            <br />
            "paytxfee": 0.00000000,
            <br />
            "hdmasterkeyid": "6533b67b39ccc76c6906c11b2e6efb2c258e2a47"
            <br />
            &#125;
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            Your walletname is still the same, which means that your old
            unencrypted <ElonCoinCliSpan>wallet.dat</ElonCoinCliSpan> was
            overwritten by the new encrypted{' '}
            <ElonCoinCliSpan>wallet.dat</ElonCoinCliSpan>. However, for safety,
            your old seed is kept in the new encrypted wallet, in case you had
            actual funds in the old wallet, or if you accidentally receive funds
            to that old wallet in the future.
          </CommonP>
          <CommonH1Black>A.4.2 Backup the wallet</CommonH1Black>
          <CommonP>
            We have created an encrypted wallet, and before we start using it we
            need to backup the wallet. In Chapter 4 we talked about mnemonic
            sentences, as defined in BIP39, that made backups of a hierarchical
            deterministic wallet seed really simple. However, this feature is
            not implemented in ElonCoin Core. There are a few reasons for this,
            the main reasons are that the mnemonic sentence lacks information
            about:
          </CommonP>
          <ElonCoinCliList>
            <ElonCoinCliItems>version of seed format</ElonCoinCliItems>
            <ElonCoinCliItems>
              "birthday", which is when the seed was created. Without a birthday
              you have to scan the whole blockchain to find your old
              transactions. With a birthday you’d only have to scan the
              blockchain from the birthday and forward.
            </ElonCoinCliItems>
            <ElonCoinCliItems>
              the derivation paths to use for restoration. This is somewhat
              remedied by using standard derivation paths, but not all wallets
              implement the standard.
            </ElonCoinCliItems>
            <ElonCoinCliItems>
              Other arbitrary metadata, such as labels on addresses.
            </ElonCoinCliItems>
          </ElonCoinCliList>
          <CommonP>
            So in order to backup your ElonCoin Core wallet, you need to make a
            copy of the <ElonCoinCliSpan>wallet.dat</ElonCoinCliSpan> file. Be
            careful not to copy the file using your operating system’s copy
            facilities while <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> or{' '}
            <ElonCoinCliSpan>eloncoin-qt</ElonCoinCliSpan> is running. If you do
            that, your backup might be in an inconsistent state because{' '}
            <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> might be writing data
            to it while you copy. To make sure you get a consistent copy of the
            file while ElonCoin Core is running, please run the command
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli backupwallet ~/walletbackup.dat
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            This will instruct <ElonCoinCliSpan>eloncoind</ElonCoinCliSpan> to
            save a copy of the wallet file to{' '}
            <ElonCoinCliSpan>walletbackup.dat</ElonCoinCliSpan> in your home
            directory, but you can change the name and path of the file to
            anything you like. The backup file will be an exact copy of the
            original <ElonCoinCliSpan>wallet.dat</ElonCoinCliSpan> file. Move
            the <ElonCoinCliSpan>walletbackup.dat</ElonCoinCliSpan> file to a
            safe place, for example a USB memory in bank deposit box, or on a
            computer at your brother’s apartment.
          </CommonP>
          <CommonH1Black>A.4.3 Receive money</CommonH1Black>
          <CommonP>
            You have created an encrypted, backed up wallet. Great! Let’s put
            some eloncoins into your wallet. To do that you need a ElonCoin
            address to receive the eloncoins to, so let’s get one:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli getnewaddress "label"
            <br />
            EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq
          </ElonCoinCliPre>
          <CommonP>
            Now, let’s send eloncoin to that address. Please be
            carefElonCoinCliList to not send money to the address printed in
            this book, though the author woElonCoinCliListd happily accept it,
            but to an address that you generate yourself with your own
            fElonCoinCliListl node wallet.
          </CommonP>
          <CommonP>
            This opens the question on how to actually get eloncoins to send to
            your wallet. There are several ways to get eloncoin:
          </CommonP>
          <ElonCoinCliList>
            <ElonCoinCliItems>Buy eloncoins on an exchange</ElonCoinCliItems>
            <ElonCoinCliItems>
              Ask a friend who has eloncoins if she can give or sell you some
            </ElonCoinCliItems>
            <ElonCoinCliItems>
              Earn eloncoins as payment for your labor
            </ElonCoinCliItems>
            <ElonCoinCliItems>Stake eloncoins</ElonCoinCliItems>
          </ElonCoinCliList>
          <CommonP>Why "-qt"?</CommonP>
          <CommonP>
            Please visit <a href="/#">Web resource 20</a> to find out more about
            how to get eloncoins where you live.
          </CommonP>
          <CommonP>
            I will leave it up to you how you get hold of eloncoins and just
            assume that you somehow will get eloncoins into the address you
            created above.
          </CommonP>
          <CommonP>
            When you have made the payment to your new address, please check
            your wallet:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli getunconfirmedbalance
            <br />
            0.00500000
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            This shows that you have a pending incoming payment of 5 mEMC (0.005
            EMC). We now have to wait until it’s confirmed in the blockchain.
            Meanwhile, we can dig into the transaction by running{' '}
            <ElonCoinCliSpan> thelisttransactions</ElonCoinCliSpan> command:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli listtransactions
            <br />
            [<br />
            &#123;
            <br />
            "account": "",
            <br />
            "address": "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq",
            <br />
            "category": "receive",
            <br />
            "amount": 0.00500000,
            <br />
            "label": "",
            <br />
            "vout": 1,
            <br />
            "confirmations": 0,
            <br />
            "trusted": false,
            <br />
            "txid":
            "30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170",
            <br />
            "walletconflicts": [<br />
            ],
            <br />
            "time": 1527068938,
            <br />
            "timereceived": 1527068938,
            <br />
            "bip125-replaceable": "yes"
            <br />
            &#125;
            <br />]
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            We can see that the transaction has 0 confirmations and that it pays
            us 0.005 EMC. We also see that the txid of this transaction is{' '}
            <ElonCoinCliSpan>
              30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170{' '}
            </ElonCoinCliSpan>
            .
          </CommonP>
          <CommonP>
            Let’s take a closer look at the transaction using the command{' '}
            <ElonCoinCliSpan>getrawtransaction</ElonCoinCliSpan>:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli getrawtransaction \<br />
            30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170 1
            <br />
            &#123;
            <br />
            "txid":
            "30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170",
            <br />
            "hash":
            "30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170",
            <br />
            "version": 1,
            <br />
            "size": 222,
            <br />
            "vsize": 222,
            <br />
            "locktime": 523985,
            <br />
            "vin": [<br />
            &#123;
            <br />
            "txid":
            "7614149b575053426914ed7dafd5f81eb9d4544965253a9b50d19a8ec5b86cff",
            <br />
            "vout": 0,
            <br />
            "scriptSig": &#123;
            <br />
            "asm":
            <br />
            "3044022044a707325cfd09f41ac232ae7f06c31721f894a70bee2153fbfb0e643d20ffd602207c9c68803f72f93c3c240533325a35e9415338f9629196afa9fbb584d22a1c29[ALL]
            <br />
            035a1a39c06ab2f5a38c017ad93dd17fdbca23b06f6c115853df06b8de59765b14",
            <br />
            "hex":
            <br />
            "473044022044a707325cfd09f41ac232ae7f06c31721f894a70bee2153fbfb0e643d20ffd602207c9c68803f72f93c3c240533325a35e9415338f9629196afa9fbb584d22a1c290121035a1a39c06ab2f5a38c017ad93dd17fdbca23b06f6c115853df06b8de59765b14"
            <br />
            &#125;,
            <br />
            "sequence": 4294967293
            <br />
            &#125;
            <br />
            ],
            <br />
            "vout": [<br />
            &#123;
            <br />
            "value": 0.00399777,
            <br />
            "n": 0,
            <br />
            "scriptPubKey": &#123;
            <br />
            "asm": "OP_DUP OP_HASH160 0cec13fbeb631c410a8cf2bafeb13166e5b37a36
            OP_EQUALVERIFY OP_CHECKSIG",
            <br />
            "hex": "76a9140cec13fbeb631c410a8cf2bafeb13166e5b37a3688ac",
            <br />
            "reqSigs": 1,
            <br />
            "type": "pubkeyhash",
            <br />
            "addresses": [<br />
            "12BKvJ1kknZZN2TqU7wMkmYJN5oxfxCHML"
            <br />
            ] &#125;
            <br />
            &#125;,
            <br />
            &#123;
            <br />
            "value": 0.00500000,
            <br />
            "n": 1,
            <br />
            "scriptPubKey": &#123;
            <br />
            "asm": "0 8fe206383b23128d7834041c68bc3280308311b9",
            <br />
            "hex": "00148fe206383b23128d7834041c68bc3280308311b9",
            <br />
            "reqSigs": 1,
            <br />
            "type": "witness_v0_keyhash",
            <br />
            "addresses": [<br />
            "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq"
            <br />
            ]<br />
            &#125;
            <br />
            &#125;
            <br />
            ],
            <br />
            "hex":
            <br />
            "0100000001ff6cb8c58e9ad1509b3a25654954d4b91ef8d5af7ded1469425350579b141476000000006a473044022044a707325cfd09f41ac232ae7f06c31721f894a70bee2153fbfb0e643d20ffd602207c9c68803f72f93c3c240533325a35e9415338f9629196afa9fbb584d22a1c290121035a1a39c06ab2f5a38c017ad93dd17fdbca23b06f6c115853df06b8de59765b14fdffffff02a1190600000000001976a9140cec13fbeb631c410a8cf2bafeb13166e5b37a3688ac20a10700000000001600148fe206383b23128d7834041c68bc3280308311b9d1fe0700"
            <br />
            &#125;
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            This command prints the whole transaction in a human-readable (well
            at least developer-readable) form. Let’s start from the top and go
            through the most relevant parts of this transaction. The{' '}
            <ElonCoinCliSpan>txid </ElonCoinCliSpan> is the transaction id of
            this transaction. The <ElonCoinCliSpan>hash</ElonCoinCliSpan> is the
            double SHA256 hash of the whole transaction, including the witness.
            For non-segwit transactions, <ElonCoinCliSpan>hash</ElonCoinCliSpan>{' '}
            is equal to <ElonCoinCliSpan>txid</ElonCoinCliSpan>.
          </CommonP>
          <CommonP>
            The <ElonCoinCliSpan>size</ElonCoinCliSpan> of the transaction is
            222 bytes, and <ElonCoinCliSpan>vsize </ElonCoinCliSpan> (virtual
            size) is also 222 vbytes. <ElonCoinCliSpan>vsize</ElonCoinCliSpan>{' '}
            is the number of weight units of the transaction divided by 4, so
            the virtual size of a non-segwit transaction (which this is, because
            it only spends non-segwit outputs) is equal to its actual{' '}
            <ElonCoinCliSpan>size</ElonCoinCliSpan>.
          </CommonP>
          <CommonP>
            The locktime of this transaction is set to 523985 which was the
            height of the strongest chain at the time of transaction creation.
            This means that the transaction cannot be mined until block height
            523986. This is to reduce the attractiveness of an attack where a
            miner deliberately tries to reorg the blockchain and include your
            transaction into a block height that’s already been mined.
          </CommonP>
          <CommonP>
            Next comes the list of inputs. This transaction has a single input
            that spends output at index <ElonCoinCliSpan>0</ElonCoinCliSpan> (
            <ElonCoinCliSpan>vout</ElonCoinCliSpan>) of the transaction with{' '}
            <ElonCoinCliSpan>
              txid7614149b575053426914ed7dafd5f81eb9d4544965253a9b50d19a8ec5b86cff
            </ElonCoinCliSpan>
            . This input spends a p2pkh output.
          </CommonP>
          <CommonP>
            The sequence number of the input is{' '}
            <ElonCoinCliSpan>4294967293</ElonCoinCliSpan>, which is{' '}
            <ElonCoinCliSpan>fffffffd</ElonCoinCliSpan> in hex code. This means
            that locktime is enabled (⇐
            <ElonCoinCliSpan> fffffffe</ElonCoinCliSpan>), and that the
            transaction is replaceable (⇐
            <ElonCoinCliSpan>fffffffd</ElonCoinCliSpan>) according to BIP125.
            The meaning of the sequence number was summarized in Table 28.
          </CommonP>
          <CommonP>
            After the list of inputs comes the list of transaction outputs. This
            transaction has a list of 2 outputs. The first output pays
            0.00399777 EMC to a p2pkh address that we haven’t seen before. This
            is probably a change output. The second output sends 0.005 EMC to
            the p2wpkh address we created above.
          </CommonP>
          <CommonP>
            Let’s see if the transaction is confirmed yet. You can check for
            example with <ElonCoinCliSpan>getbalance </ElonCoinCliSpan>. If it
            shows <ElonCoinCliSpan>0.00500000</ElonCoinCliSpan> it means that
            that the transaction has confirmed:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli getbalance
            <br />
            0.00500000
            <br />
            &#125;
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            Cool, the money is confirmed! It’s yours! Let’s move on.
          </CommonP>
          <CommonH1Black>A.4.4 Send money</CommonH1Black>
          <CommonP>
            We have received some eloncoins. Now we want to send eloncoins to
            someone else. To send eloncoins, you can use the{' '}
            <ElonCoinCliSpan>sendtoaddresscommand</ElonCoinCliSpan>. We need to
            make a few decisions first:
          </CommonP>
          <ElonCoinCliList>
            <ElonCoinCliItems>
              What address to send to: EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq
            </ElonCoinCliItems>
            <ElonCoinCliItems>
              How much money to send: 0.001 EMC
            </ElonCoinCliItems>
            <ElonCoinCliItems>
              How urgent the transaction is: Not urgent (we’re happy if it
              confirms within 20 blocks)
            </ElonCoinCliItems>
          </ElonCoinCliList>
          <CommonP>
            I will send the eloncoins to address{' '}
            <ElonCoinCliSpan>
              EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq{' '}
            </ElonCoinCliSpan>
            , but you shoElonCoinCliListd get another address to send to. If you
            have no other wallet, you can create a new address in ElonCoin Core
            to send to just for experimental purposes.
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli sendtoaddress \<br />
            "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq" \<br />
            0.001
            <br />
            error code: -13
            <br />
            error message:
            <br />
            Error: Please enter the wallet passphrase with walletpassphrase
            first.
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            Oh, dear! We got an error. As indicated by the error message, this
            is because the private keys are encrypted in the{' '}
            <ElonCoinCliSpan>wallet.dat</ElonCoinCliSpan> file. ElonCoin Core
            needs the private keys to sign the transaction. To make the private
            keys accessible, you need to decrypt them. You do that using the{' '}
            <ElonCoinCliSpan>walletpassphrase </ElonCoinCliSpan> command with
            the <ElonCoinCliSpan>-stdin</ElonCoinCliSpan> option to prevent the
            passphrase from being stored by your command line interpreter, for
            example bash.
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli -stdin walletpassphrase
            <br />
            secretpassword
            <br />
            300'&le;'ENTER'&gt;'
            <br />
            '&le;'CTRL-D'&gt;'
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The last argument, <ElonCoinCliSpan>300</ElonCoinCliSpan>, is the
            number of seconds to keep the wallet unlocked. After 300 seconds,
            the wallet will be automatically locked again in case we forget to
            lock it manually. Let’s retry our{' '}
            <ElonCoinCliSpan>sendtoaddress</ElonCoinCliSpan> command again:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli sendtoaddress \<br />
            "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq" \<br />
            0.001
            <br />
            7b4157483edae7d30bfa9a2672c9729370eaf8f6e32c4ff2bc2ece1b982658bf
            <br />
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The command output a txid for the newly created transaction. This
            means that it went well. We can now lock the wallet again using the{' '}
            <ElonCoinCliSpan>walletlock</ElonCoinCliSpan> command:
          </CommonP>
          <ElonCoinCliPre>$ ./eloncoin-cli walletlock</ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The wallet is now locked. Let’s list our transactions again:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli listtransactions
            <br />
            [<br />
            &#123;
            <br />
            "account": "",
            <br />
            "address": "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq",
            <br />
            "category": "receive",
            <br />
            "amount": 0.00500000,
            <br />
            "label": "",
            <br />
            "vout": 1,
            <br />
            "confirmations": 1,
            <br />
            "blockhash":
            "0000000000000000003882bb3554816a68ee175057c58fa7c03bc1a1eedb0e3c",
            <br />
            "blockindex": 1067,
            <br />
            "blocktime": 1527070201,
            <br />
            "txid":
            "30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170",
            <br />
            "walletconflicts": [<br />
            ],
            <br />
            "time": 1527068938,
            <br />
            "timereceived": 1527068938,
            <br />
            "bip125-replaceable": "no"
            <br />
            &#125;,
            <br />
            &#123;
            <br />
            "account": "",
            <br />
            "address": "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq",
            <br />
            "category": "send",
            <br />
            "amount": -0.00100000,
            <br />
            "vout": 0,
            <br />
            "fee": -0.00000141,
            <br />
            "confirmations": 0,
            <br />
            "trusted": true,
            <br />
            "txid":
            "7b4157483edae7d30bfa9a2672c9729370eaf8f6e32c4ff2bc2ece1b982658bf",
            <br />
            "walletconflicts": [<br />
            ],
            <br />
            "time": 1527070565,
            <br />
            "timereceived": 1527070565,
            <br />
            "bip125-replaceable": "no",
            <br />
            "abandoned": false
            <br />
            &#125;
            <br />]
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The new transaction is the last one of the two. It is not yet
            confirmed, as indicated by{' '}
            <ElonCoinCliSpan>"confirmations": 0</ElonCoinCliSpan>. The fee we
            paid was 141 satoshis. We will look into this transaction in detail:
          </CommonP>
          <ElonCoinCliPre>
            $ ./eloncoin-cli getrawtransaction \<br />
            7b4157483edae7d30bfa9a2672c9729370eaf8f6e32c4ff2bc2ece1b982658bf 1
            <br />
            &#123;
            <br />
            "txid":
            "7b4157483edae7d30bfa9a2672c9729370eaf8f6e32c4ff2bc2ece1b982658bf",
            <br />
            "hash":
            "e5fd28d1a2a0f1741a4ec465375ae0aa5619f5b82f42026d3566018d4b81690b",
            <br />
            "version": 2,
            <br />
            "size": 223,
            <br />
            "vsize": 141,
            <br />
            "locktime": 523988,
            <br />
            "vin": [<br />
            &#123;
            <br />
            "txid":
            "30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170",
            <br />
            "vout": 1,
            <br />
            "scriptSig": &#123;
            <br />
            "asm": "",
            <br />
            "hex": ""
            <br />
            &#125;,
            <br />
            "txinwitness": [<br />
            "3045022100e718670f69693b884c21d15a3e78a8e8e33b7e2b68c51510b9531a7980b5626c02206ff5ed5d897851bd25e76b00f84f0e2e25df3c86394880650012de515af18bf501",
            "038fa2827f90fc158e80845424fc67dfb493263669919d986608caf11237ff8876"
            <br />
            ],
            <br />
            "sequence": 4294967294
            <br />
            &#125;
            <br />
            ],
            <br />
            "vout": [<br />
            &#123;
            <br />
            "value": 0.00100000,
            <br />
            "n": 0,
            <br />
            "scriptPubKey": &#123;
            <br />
            "asm": "0 1d2dcc9d7c408f2dbe034eac84ffbf3e1453fd1a",
            <br />
            "hex": "00141d2dcc9d7c408f2dbe034eac84ffbf3e1453fd1a",
            <br />
            "reqSigs": 1,
            <br />
            "type": "witness_v0_keyhash",
            <br />
            "addresses": [<br />
            "EX6CtLffibY5oTGk1ui1eZSgEecoRVrohq"
            <br />
            ]<br />
            &#125;
            <br />
            &#125;,
            <br />
            &#123;
            <br />
            "value": 0.00399859,
            <br />
            "n": 1,
            <br />
            "scriptPubKey": &#123;
            <br />
            "asm": "0 a642a2a405470abdc8db9c08f77e3f79d17cf3ee",
            <br />
            "hex": "0014a642a2a405470abdc8db9c08f77e3f79d17cf3ee",
            <br />
            "reqSigs": 1,
            <br />
            "type": "witness_v0_keyhash",
            <br />
            "addresses": [<br />
            "bc1q5ep29fq9gu9tmjxmnsy0wl3l08gheElonCoinCliListw4nau0c"
            <br />
            ]<br />
            &#125;
            <br />
            &#125;
            <br />
            ],
            <br />
            "hex": <br />
            "0200000000010170f12ef6280fe00b3cc64c3ac2703739bdf487c2656ac3c111b858affea6bc300100000000feffffff02a0860100000000001600141d2dcc9d7c408f2dbe034eac84ffbf3e1453fd1af319060000000000160014a642a2a405470abdc8db9c08f77e3f79d17cf3ee02483045022100e718670f69693b884c21d15a3e78a8e8e33b7e2b68c51510b9531a7980b5626c02206ff5ed5d897851bd25e76b00f84f0e2e25df3c86394880650012de515af18bf50121038fa2827f90fc158e80845424fc67dfb493263669919d986608caf11237ff8876d4fe0700"
            <br />
            &#125;
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            The first thing to note is that the{' '}
            <ElonCoinCliSpan>size</ElonCoinCliSpan> and{' '}
            <ElonCoinCliSpan>vsize </ElonCoinCliSpan> differ. That’s because
            this is a segwit transaction. The fee was 141 satoshis, as shown by
            the <ElonCoinCliSpan>listtransactions</ElonCoinCliSpan>
            command above, and the vsize is 141 vbytes. The fee-rate was thus
            selected by ElonCoin Core to be 1 sat/vbyte.
          </CommonP>
          <CommonP>
            The transaction has a single input that spends output 1 of{' '}
            <ElonCoinCliSpan>
              {' '}
              transaction30bca6feaf58b811c1c36a65c287f4bd393770c23a4cc63c0be00f28f62ef170
            </ElonCoinCliSpan>
            . We recognize this output from the section where we paid 0.005 EMC
            to our ElonCoin Core wallet. Since that output was a p2wpkh output,
            the signature script (<ElonCoinCliSpan>scriptSig</ElonCoinCliSpan>)
            is empty, and the <ElonCoinCliSpan>txinwitness</ElonCoinCliSpan>{' '}
            contains the signature and pubkey.
          </CommonP>
          <CommonP>
            The sequence number of the input is 4294967294 which equals
            'fffffffe'. This means that the transaction has locktime enabled,
            but is not replaceable using BIP125 (Opt-in replace by fee).
          </CommonP>
          <CommonP>
            We have two outputs: The first one is the actual payment of 0.001
            EMC. The other is the change of 0.00399859 back to an address of our
            own. Let’s check our balance to see if the transaction is confirmed:
          </CommonP>
          <ElonCoinCliPre>
            ./eloncoin-cli getbalance
            <br />
            0.00399859
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>
            Yep, there it is. We’ve spent our only TXO (of 0.005 EMC) and
            created a new TXO of 0.00399859 to ourselves. So{' '}
          </CommonP>
          <ElonCoinCliPre>
            Spent: 0.005
            <br />
            Pay: -0.001
            <br />
            Fee: -0.00000141
            <br />
            ===================
            <br />
            Change: -0.00399859
            <br />
            0.00399859
          </ElonCoinCliPre>
          <ElonCoinCliButton>Copy</ElonCoinCliButton>
          <CommonP>It sums up perfectly.</CommonP>
          <CommonP>
            We have shown a few commands you can use to wing your ElonCoin Core
            node, but there’s a lot more to it. Please explore{' '}
            <ElonCoinCliSpan>./eloncoin-cli help</ElonCoinCliSpan> to find out
            more.
          </CommonP>
        </ElonCoinCliComponentContent>
      </CommonContainer>
    </ElonCoinCliComponentSection>
  );
}
