import { BaseContract } from '../baseContract';
import contractAbi from './abi.json';

export class AdminSwapperContract extends BaseContract {
  constructor(address, provider) {
    super(address, contractAbi, provider);
  }

  async swapByAdmin(oldEmcPubKey, oldEmcAmount, receiver) {
    return this.writer.swapByAdmin(oldEmcPubKey, oldEmcAmount, receiver);
  }

  async EMC_UNIT() {
    return this.reader.EMC_UNIT();
  }

  async swappedAmountByReceiverPubKey(oldEmcPubKey) {
    return this.reader.swappedAmountByReceiverPubKey(oldEmcPubKey);
  }
}
