import React from 'react';
import styled from 'styled-components';

import SilverBackgroundImage from '../../assets/images/silverBg.png';
import UserImage from '../../assets/images/user.png';
import UserImage1 from '../../assets/images/user1.png';
import LeftWImage from '../../assets/images/leftW.png';
import RightWImage from '../../assets/images/rightW.png';

const TestimonialSection = styled.div`
  background-image: url(${SilverBackgroundImage});
  padding-top: 100px;
  padding-top: ${(props) => props.paddingtop};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const TestimonialInnerBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexdirection};
  @media (max-width: 992px) {
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const TestimonialUserImgDiv = styled.div`
  position: relative;
  bottom: 0px;
  @media (max-width: 767px) {
    order: 2;
  }
`;

const TestimonialUserImg = styled.img`
  max-width: 500px;
  width: 100%;
  @media (max-width: 992px) {
    max-width: 400px;
  }
  @media (max-width: 767px) {
    max-width: 300px;
  }
`;

const TestimonialTextbox = styled.div`
  position: relative;
  width: 486px;
  height: 233px;
  @media (max-width: 992px) {
    width: 514px;
    height: auto;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 380px;
  }
`;

const TestimonialTextImg = styled.img`
  width: 100%;
`;

const TestimonialOrTextDiv = styled.div`
  position: absolute;
  top: ${(props) => (props.direction === 'left' ? '40%' : '40%')};
  left: ${(props) => (props.direction === 'left' ? '50%' : '40%')};
  width: 66%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  max-height: 62%;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: normal;
  }
`;

export default function Testimonial(props) {
  return (
    <TestimonialSection
      id={`testimonial-${props.direction}`}
      paddingtop={props.direction === 'left' ? '100px' : '200px'}
    >
      <CommonContainer>
        <TestimonialInnerBox
          flexdirection={props.direction === 'left' ? 'row' : 'row-reverse'}
        >
          <TestimonialUserImgDiv>
            <TestimonialUserImg
              src={props.direction === 'left' ? UserImage1 : UserImage}
            />
          </TestimonialUserImgDiv>
          <TestimonialTextbox>
            <TestimonialTextImg
              src={props.direction === 'left' ? LeftWImage : RightWImage}
            />
            <TestimonialOrTextDiv direction={props.direction}>
              Persistence is very important. You should not give up unless you
              are forced to give up.
            </TestimonialOrTextDiv>
          </TestimonialTextbox>
        </TestimonialInnerBox>
      </CommonContainer>
    </TestimonialSection>
  );
}
