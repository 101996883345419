import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../../assets/images/logo_3.0@2x.png';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';

const HeaderLayout = styled.div`
  width: 100%;
  float: left;
`;

const Headers = styled.header`
  display: block;
  padding: 10px 0;
  background: rgb(0 0 0 / 72%);
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 9;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const HeaderDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoDiv = styled.div`
  width: 80px;
`;

const LogoImg = styled.img`
  width: 80px;
  @media only screen and (max-width: 991px) {
    width: 51px;
  }
`;

const Navigation = styled.nav`
  transition: all 0.5s linear;
  width: calc(100% - 102px);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavigationList = styled.ul`
  display: flex;
  margin-bottom: 0;
`;

const NavigationItem = styled.li`
  list-style: none;
  padding: 0 13px;
  cursor: pointer;
  &:hover {
    color: #c59d5f;
  }
`;

const CommonButtons = styled.button`
  display: inline-block;
  background: rgb(255 255 255 / 12%);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  padding: 9px 16px;
  font-weight: 200;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  margin-left: 0.5rem;
  &:hover {
    box-shadow: none;
    background-color: rgb(183 145 85);
  }
`;

const Hamburger = styled.div`
  cursor: pointer;
`;

const HamburgerLine = styled.div`
  width: 25px;
  height: 2px;
  background-color: #ffffff;
  margin-bottom: 5px;
  border-radius: 5px;
  box-sizing: border-box;
`;

const HamburgerMenu = styled.div`
  position: absolute;
  top: 69px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    119deg,
    rgba(148, 98, 69, 1) 0%,
    rgba(154, 111, 83, 1) 35%,
    rgba(167, 121, 91, 1) 89%,
    rgba(96, 61, 40, 1) 100%
  );
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  text-align: left;
  z-index: 9;
`;

const HamburgerMenuList = styled.ul`
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 0;
  list-style: none;
`;

const HamburgerMenuItem = styled.li`
  width: 100%;
  text-align: left;
  padding: 0 0 20px 0;
  list-style: none;
  margin: 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #c59d5f;
  }
`;

export default function HeaderComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const [windowSize, setWindowSize] = useState([0, 0]);
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const scrollNavTo = (scrollTo) => {
    setShowMenu(false);
    let element = document.getElementById(scrollTo);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const navigateTo = (to) => {
    navigate(to);
  };

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const logOut = () => {
    dispatch({ type: 'LOG_OUT' });
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    logOut();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderLogout = () => {
    return (
      <div>
        <div className="avatar-container">
          <Avatar
            name={user.user_name}
            size="40"
            round={true}
            color={'#d08715'}
            style={{ cursor: 'pointer' }}
            onClick={(e) => toggleDropdown(e)}
          >
            Account
          </Avatar>
          {showDropdown && (
            <div className="dropdown-menu" ref={dropdownRef}>
              <CommonButtons onClick={() => navigateTo('/myaccount')}>
                My Account
              </CommonButtons>
              {user.role === 'admin' ? (
                <CommonButtons onClick={() => navigateTo('/swap')}>
                  Swaps
                </CommonButtons>
              ) : null}
              <CommonButtons onClick={() => navigateTo('/wallet')}>
                My Wallet
              </CommonButtons>
              <CommonButtons
                onClick={() => navigateTo(`/history/${user.user_name}`)}
              >
                Swap History
              </CommonButtons>
              <CommonButtons onClick={() => navigateTo('/settings')}>
                Settings
              </CommonButtons>
              <CommonButtons onClick={handleLogout}>Log out</CommonButtons>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <HeaderLayout>
      <Headers>
        <CommonContainer>
          <HeaderDiv>
            <LogoDiv>
              <LogoImg src={Logo} alt="Elon Coin" />
            </LogoDiv>
            {windowSize[0] > 992 && (
              <Navigation>
                <NavigationList>
                  <NavigationItem onClick={() => navigateTo('/')}>
                    Home
                  </NavigationItem>
                  <NavigationItem
                    onClick={() => scrollNavTo('testimonial-left')}
                  >
                    Why ElonCoin
                  </NavigationItem>
                  <NavigationItem onClick={() => scrollNavTo('faq')}>
                    FAQ
                  </NavigationItem>
                  <NavigationItem onClick={() => scrollNavTo('wallet')}>
                    Wallet
                  </NavigationItem>
                  <NavigationItem onClick={() => navigateTo('/user-guide')}>
                    User guide
                  </NavigationItem>
                  <NavigationItem onClick={() => scrollNavTo('contactus')}>
                    Contact us
                  </NavigationItem>
                </NavigationList>
                {user ? (
                  renderLogout()
                ) : (
                  <div>
                    <CommonButtons onClick={() => navigateTo('/login')}>
                      Login
                    </CommonButtons>
                    <CommonButtons onClick={() => navigateTo('/signup')}>
                      Sign up
                    </CommonButtons>
                  </div>
                )}
              </Navigation>
            )}
            {windowSize[0] < 993 && (
              <Hamburger onClick={() => handleMenu()}>
                <HamburgerLine />
                <HamburgerLine />
                <HamburgerLine />
              </Hamburger>
            )}
            {showMenu && (
              <HamburgerMenu>
                <HamburgerMenuList>
                  <HamburgerMenuItem onClick={() => scrollNavTo('')}>
                    Home
                  </HamburgerMenuItem>
                  <HamburgerMenuItem
                    onClick={() => scrollNavTo('testimonial-left')}
                  >
                    Why ElonCoin
                  </HamburgerMenuItem>
                  <HamburgerMenuItem onClick={() => scrollNavTo('faq')}>
                    FAQ
                  </HamburgerMenuItem>
                  <HamburgerMenuItem onClick={() => scrollNavTo('wallet')}>
                    Wallet
                  </HamburgerMenuItem>
                  <HamburgerMenuItem onClick={() => navigateTo('/user-guide')}>
                    User guide
                  </HamburgerMenuItem>
                  <HamburgerMenuItem onClick={() => scrollNavTo('contactus')}>
                    Contact us
                  </HamburgerMenuItem>
                </HamburgerMenuList>
                {user ? (
                  renderLogout()
                ) : (
                  <>
                    <CommonButtons onClick={() => navigateTo('/login')}>
                      Login
                    </CommonButtons>
                    <CommonButtons onClick={() => navigateTo('/signup')}>
                      Sign up
                    </CommonButtons>
                  </>
                )}
              </HamburgerMenu>
            )}
          </HeaderDiv>
        </CommonContainer>
      </Headers>
    </HeaderLayout>
  );
}
