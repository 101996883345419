import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

import StarBackgroundImage from '../../assets/images/Starbackground@2x.png';
import Modal from 'styled-react-modal';
import { listUsers } from '../../reducers/userReducer';
import SwapComponent from '../SwapComponent';
import { useAdminSwapperContract } from '../../hooks/useContract';

const SwapsSection = styled.div`
  background: url(${StarBackgroundImage});
  padding: 96px 0 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
  display_: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 2;
  margin-bottom: -150px;
`;

const CommonContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const SwapsContentDiv = styled.div`
  padding: 50px;
  position: relative;
  z-index: 1;
`;

const SwapsContentFormDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.29);
  padding: 20px;
  width: 90%;
  max-width: 600px;
  @media (max-width: 992px) {
    padding: 30px;
    width: 90%;
  }
`;

const SwapsContentFormTitle = styled.h1`
  font-size: 30px;
  line-height: 70px;
  margin-bottom: 15px;
  display: inline-block;
  margin: 0px;
`;

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  `;

const SwapTable = styled.table`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-collapse: collapse;
  &&& {
    td {
      background-color: #69440b20;
    }
    tr:nth-child(even) {
      background-color: #69440b70;
    }
  }
`;

const SwapTh = styled.th`
  text-align: ${(props) =>
    props.cellalign !== null ? props.cellalign : 'left'};
  padding: 8px;
`;

const SwapTr = styled.tr`
  background-color: #69440bbb;
`;

const PaginationDiv = styled.div`
  margin-top: 10px;
  display: block;
  &&& {
    ul li {
      min-width: 30px;
      text-align: center;
      display: inline-block;
      border-radius: 2px;
      background: #00000050;
      cursor: pointer;
    }
    ul li:hover {
      background: #ffffff50;
    }
    ul li.selected {
      background: #ffffff50;
    }
    ul li a {
      padding: 8px;
      display: block;
    }
  }
`;

const SearchDiv = styled.div`
  display: inline-block;
  float: right;
  margin-top: 25px;
`;
const SearchInput = styled.input`
  padding: 6px;
  background-color: #444444;
  border: solid 1px #ffffff55;
  font-size: 14px;
  color: white;
  border-radius: 3px;
`;

export default function SwapsComponent() {
  const pageSize = 10;
  const dispatch = useDispatch();
  const adminSwapperContract = useAdminSwapperContract();

  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  const handlePageClick = (event) => {
    setPageNumber(event.selected);
  };

  const getUsers = useCallback(async () => {
    try {
      let data = {
        pageSize,
        pageNumber,
        search,
      };

      const res = await dispatch(listUsers({ data }));
      setPageCount(res.payload?.totalPages ?? 0);

      if (listUsers.fulfilled.match(res)) {
        setUsers(res.payload?.users ?? []);
      } else {
        if (res.payload.statusCode === 401) {
          dispatch({ type: 'LOG_OUT' });
        }
      }
    } catch (error) {
      console.log(error?.message);
    }
  }, [dispatch, pageNumber, search]);

  useEffect(() => {
    getUsers();
  }, [pageNumber, getUsers]);

  return (
    <SwapsSection>
      <CommonContainer>
        <SwapsContentDiv>
          <SwapsContentFormDiv>
            <SwapsContentFormTitle>Swaps</SwapsContentFormTitle>
            <SearchDiv>
              <SearchInput
                placeholder="Search..."
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(e.target.value);
                  }
                }}
              />
            </SearchDiv>

            <SwapTable>
              <thead>
                <SwapTr>
                  <SwapTh cellalign="left">User Name</SwapTh>
                  <SwapTh cellalign="left">Old Address</SwapTh>
                  <SwapTh cellalign="left">New Address</SwapTh>
                  <SwapTh cellalign="center">Swap</SwapTh>
                </SwapTr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <SwapComponent
                    swap={user}
                    key={user.user_name}
                    adminSwapperContract={adminSwapperContract}
                  />
                ))}
              </tbody>
            </SwapTable>

            <PaginationDiv>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< prev"
                renderOnZeroPageCount={null}
              />
            </PaginationDiv>
          </SwapsContentFormDiv>
        </SwapsContentDiv>
      </CommonContainer>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      ></StyledModal>
    </SwapsSection>
  );
}
