import { createAsyncThunk } from '@reduxjs/toolkit';
import RPCService from '../services/rpcService';

export const swapOldCoin = createAsyncThunk(
  'rpc/swap',
  async ({ username }, thunkAPI) => {
    try {
      const res = await RPCService.swap(username);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOldCoinBalance = createAsyncThunk(
  'rpc/old-balance',
  async ({ address }, thunkAPI) => {
    try {
      const res = await RPCService.getOldBalance(address);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAddressInfo = createAsyncThunk(
  'rpc/address-info',
  async ({ address }, thunkAPI) => {
    try {
      const res = await RPCService.getAddressInfo(address);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNewCoinBalance = createAsyncThunk(
  'rpc/new-balance',
  async ({ address }, thunkAPI) => {
    try {
      const res = await RPCService.getNewBalance(address);
      return Promise.resolve(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
